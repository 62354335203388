import React from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Home from './pages/Home';
import Merchants from './pages/Merchants';
import Register from './pages/Register';
import SignIn from './pages/SignIn';
import Profile from './pages/Profile';
import MerchantBuds from './pages/MerchantBuds';
import Receipts from './pages/Receipts';
import DistributionBuds from './pages/Distributions';
import SearchBuds from './pages/SearchBuds';
import NewBuds from './pages/NewBuds';
import Privacy from './pages/Privacy';
import CGU from './pages/CGU';
import Account from './pages/Account';
import Contact from './pages/Contact';
import DistributionUsers from './pages/DistributionUsers';
import Forgotten from './pages/Forgotten';
import SearchBusiness from './pages/SearchBusiness';
import Notify from './pages/Notify';

function App() {

  return (
    <Router>
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/merchants' exact element={<Merchants />} />
        <Route path='/merchants/register' exact element={<Register />} />
        <Route path='/merchants/signin' exact element={<SignIn />} />
        <Route path='/merchants/forgotten' exact element={<Forgotten />} />
        <Route path='/merchants/profile' exact element={<Profile />} />
        <Route path='/merchants/account' exact element={<Account />} />
        <Route path='/merchants/contact' exact element={<Contact />} />
        <Route path='/merchants/notify' exact element={<Notify />} />
        <Route path='/merchants/buds' exact element={<MerchantBuds />} />
        <Route path='/merchants/buds/new' exact element={<NewBuds />} />
        <Route path='/merchants/receipts' exact element={<Receipts />} />
        <Route path='/merchants/distribution/:id' exact element={<DistributionBuds />} />
        <Route path='/merchants/distribution/details/:name/:bud_uuid/:distribution_token' exact element={<DistributionUsers />} />
        <Route path='/search/buds/:name' exact element={<SearchBuds />} />
        <Route path='/search/business' exact element={<SearchBusiness />} />
        <Route path='/privacy' exact element={<Privacy />} />
        <Route path='/cgu' exact element={<CGU />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;