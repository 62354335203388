import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect } from 'react';

import LOYALBUDS from "../media/images/loyalbuds_white_pro.png";
import { FaAppStore, FaDiscord, FaGooglePlay, FaInstagram, FaTwitter, FaSignOutAlt, FaLayerGroup, FaCheck, FaReceipt, FaUser, FaEnvelope, FaPaperPlane } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_OPTIONS } from '../constants';
import BusinessService from '../services/business_services';
import LOADER from "../media/images/loader.gif";
import TokenService from '../services/token_services';

//TRANSLATION
import { useTranslation } from 'react-i18next';

function Notify() {
    const { t } = useTranslation();
    const [title, setTitle] = useState("Envoyer Notifiction");
    const [message, setMessage] = useState();
    const [merchant, setMerchant] = useState({});
    const [success, setSuccess] = useState(false);
    const [processing, setProcessing] = useState(false);

    const loadAccount = async () => {
        const data = await BusinessService.business()
        setMerchant({
            name: data.business.business_name,
            website: data.business.website,
            address: data.business.address,
            city: data.business.city,
            country: data.business.country,
            description: data.business.description,
            logo: data.business.logo || data.business.logo_url,
            pro: data.business,
            user: data.user,
            business_activity_uuid: data.business.business_activity_uuid,
        })
    }

    const send = async () => {
        if (!message || message.length < 20)
            toast.error('Veuillez renseigner un message de minimum 20 caractères', TOAST_OPTIONS)
        else {
            try {
                setProcessing(true)
                const status = await BusinessService.sendNotif(
                    message)
                if (status) {
                    setProcessing(false)
                    setSuccess(true)
                    toast.success("Envoyé avec succès", TOAST_OPTIONS)
                } else {
                    setProcessing(false)
                    toast.error("Envoi échoué", TOAST_OPTIONS)
                }
            } catch (e) {
                setProcessing(false)
                toast.error(e.response && e.response.data ? e.response.data.message : e.message, TOAST_OPTIONS)
            }
        }
    }

    useEffect(() => {
        loadAccount();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="container">
            <header>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <div className="menu">
                    <div className="entry" onMouseOver={() => setTitle("Mes Buds")} onMouseOut={() => setTitle("Notifier vos clients")} onClick={() => window.location.href = "/merchants/buds"}>
                        <FaLayerGroup />
                    </div>
                    <div className="merchant" style={{
                        backgroundImage:
                            merchant.logo ?
                                `url('${merchant.logo}')`
                                :
                                merchant.logo_url ?
                                    `url('${merchant.logo_url}')`
                                    :
                                    `url('https://api.dicebear.com/7.x/identicon/svg?seed=${merchant.name}')`
                    }} onMouseOver={() => setTitle("Mon commerce")} onMouseOut={() => setTitle("Mon commerce")} onClick={() => window.location.href = "/merchants/profile"}></div>
                    <div className="entry active" onMouseOver={() => setTitle("Notifier vos clients")} onMouseOut={() => setTitle("Notifier vos clients")} onClick={() => window.location.href = "/merchants/notify"}>
                        <FaPaperPlane />
                    </div>
                    <div className="entry" onMouseOver={() => setTitle("Mes achats")} onMouseOut={() => setTitle("Notifier vos clients")} onClick={() => window.location.href = "/merchants/receipts"}>
                        <FaReceipt />
                    </div>
                    <div className="entry" onMouseOver={() => setTitle("Mon compte")} onMouseOut={() => setTitle("Notifier vos clients")} onClick={() => window.location.href = "/merchants/account"}>
                        <FaUser />
                    </div>
                    <div className="entry" onMouseOver={() => setTitle("Nous contacter")} onMouseOut={() => setTitle("Notifier vos clients")} onClick={() => window.location.href = "/merchants/contact"}>
                        <FaEnvelope />
                    </div>
                    <div className="entry" onMouseOver={() => setTitle("Déconnexion")} onMouseOut={() => setTitle("Notifier vos clients")} onClick={TokenService.removeUser}>
                        <FaSignOutAlt />
                    </div>
                </div>
            </header>
            <div className="content simple">
                <span className="notice">{title}</span>
                <h1 className="title">
                    Votre message
                </h1>
                <p>
                    Veuillez remplir toutes les informations demandées
                </p>
                <div className="form">
                    <label>
                        Message
                    </label>
                    <textarea type="text" value={message} onChange={e => setMessage(e.target.value)} />
                    {processing ?
                        <img src={LOADER} width="100" alt="Running man" />
                        :
                        success ?
                            <span className="notice">Envoyé avec succès</span>
                            :
                            <div className="button" onClick={send}>
                                <FaCheck /> Envoyer
                            </div>}
                </div>
            </div>
            <footer>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <p className="privacy">
                    <a href="/privacy">{t('privacy.policy')}</a> | <a href="/cgu">{t('cgu.general_terms')}</a>
                </p>
                <p className="copyright">
                    {t('home.website')}
                    <br />
                    <strong>Copyright @Codinsight. 2024</strong>
                </p>
                <div className="entry">
                    <p>
                        <strong>Editeur du Site</strong>
                    </p>
                    <p>
                        L'éditeur du Site est Codinsight, société par actions simplifiée au capital de 8000€,
                        immatriculée 907 943 088 au RCS de Montpellier.
                    </p>
                    <p>
                        N° TVA : FR18907943088
                        <br />
                        Email : contact@codinsight.com
                        <br />
                        Directeur de publication : M. Fabien BUCAMP.
                    </p>
                </div>
                <div className="entry">
                    <p>
                        <strong>Hébergeur du Site</strong>
                    </p>
                    <p>
                        Ce site est hébergé exclusivement sur des datacenters en France (Paris) de la société Amazon Web Services
                    </p>
                    <p>
                        Amazon Web Services Inc.
                        <br />
                        410 Terry Avenue North,
                        <br />
                        Seattle, WA 98109-5210, USA
                    </p>
                </div>
                <div className="entry">
                    <div className="link" onClick={() => window.open("https://ios.loyalbuds.app")}>
                        <FaAppStore />
                    </div>
                    <div className="link" onClick={() => window.open("https://play.loyalbuds.app")}>
                        <FaGooglePlay />
                    </div>
                    <div className="link" onClick={() => window.open("https://discord.com/invite/sHbvCwhPGz")}>
                        <FaDiscord />
                    </div>
                    <div className="link" onClick={() => window.open("https://twitter.com/LoyalBuds")}>
                        <FaTwitter />
                    </div>
                    <div className="link" onClick={() => window.open("https://www.instagram.com/loyalbudsgame/")}>
                        <FaInstagram />
                    </div>
                </div>
            </footer>
            <ToastContainer />
        </div>
    );
}

export default Notify;
