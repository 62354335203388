import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect } from 'react';

import LOYALBUDS from "../media/images/loyalbuds_white_pro.png";
import LOADER from "../media/images/loader.gif"
import { FaAppStore, FaDiscord, FaGooglePlay, FaInstagram, FaSignInAlt, FaTwitter, FaUserAstronaut, FaShoppingBag, FaCheck, FaPlay } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_OPTIONS } from '../constants';
import BusinessService from '../services/business_services'

//TRANSLATION
import { useTranslation } from 'react-i18next';

function Register() {
    const { t } = useTranslation();
    const [selectedBud, setSelectedBud] = useState({});
    const [user_email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [token, setToken] = useState(null);
    const [success, setSuccess] = useState();
    //const [business_name, setBusinessName] = useState();
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        if (window.localStorage.getItem('choosen_bud')) {
            const bud = JSON.parse(window.localStorage.getItem('choosen_bud'))
            setSelectedBud(bud)
        }
    }, []);

    const register = async () => {
        if (!user_email)
            toast.error('Veuillez remplir les champs requis', TOAST_OPTIONS);
        else if (!password || (password.length < 8))
            toast.error('Format du mot de passe incorrect', TOAST_OPTIONS);
        else {
            setProcessing(true)
            try {
                const status = await BusinessService.register(user_email, password, "web_browser")
                if (status) {
                    toast.success(`Code de validation envoyé à ${user_email}`, TOAST_OPTIONS);
                    setSuccess(true);
                }
                else
                    toast.error('Echec de la création de votre compte', TOAST_OPTIONS);
            } catch (e) {
                toast.error(e.response && e.response.data ? e.response.data.message : e.message, TOAST_OPTIONS);
            }
            setProcessing(false)
        }
    }

    const confirmAccount = async () => {
        if (!user_email || !token)
            toast.error('Veuillez remplir les champs requis', TOAST_OPTIONS);
        else if (!password || (password.length < 8))
            toast.error('Format du mot de passe incorrect', TOAST_OPTIONS);
        else {
            setProcessing(true)
            try {
                const status = await BusinessService.confirmRegistration(user_email, password, "web_browser", token)
                if (status) {
                    toast.success('Compte validé avec succès', TOAST_OPTIONS);
                    setTimeout(() => {
                        window.location.href = "/search/business"
                    }, 1000);
                }
                else
                    toast.error('Echec de la création de votre compte', TOAST_OPTIONS);
            } catch (e) {
                toast.error(e.response && e.response.data ? e.response.data.message : e.message, TOAST_OPTIONS);
            }
            setProcessing(false)
        }
    }

    return (
        <div className="container">
            <header>
                <a href="/" className="left_entry"><FaUserAstronaut /> Joueurs</a>
                <a href="/merchants" className="left_entry active"><FaShoppingBag /> Commerçants</a>
                <div className="sign_in" onClick={() => window.location.href = "/merchants/signin"}>
                    S'identifier
                </div>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <a href="https://discord.com/invite/sHbvCwhPGz" target="_blank" rel="noreferrer" className="right_entry social"><FaDiscord /></a>
                <a href="https://twitter.com/LoyalBuds" target="_blank" rel="noreferrer" className="right_entry social"><FaTwitter /></a>
                <a href="https://www.instagram.com/loyalbudsgame/" target="_blank" rel="noreferrer" className="right_entry social"><FaInstagram /></a>
                <span className="right_entry search_entry" onClick={() => window.location.href = "/merchants/signin"}><FaSignInAlt /></span>
                <span className="right_entry mobile_entry active" onClick={() => window.location.href = "/merchants"}><FaShoppingBag /></span>
                <span className="right_entry mobile_entry" onClick={() => window.location.href = "/"}><FaUserAstronaut /></span>
            </header>
            <div className="content simple">
                <span className="notice">
                    Créer votre compte dès maintenant, pour distribuer votre bud
                </span>
                <h1 className="title">
                    Get started
                </h1>
                <p>
                    Veuillez remplir toutes les informations demandées
                </p>
                {selectedBud && <div className="side_bud" style={{ backgroundImage: `url('${selectedBud.img_url}')` }}></div>}
                <div className="form">
                    {/* <label>
                        Nom de votre commerce
                    </label>
                    <input type="text" onChange={e => setBusinessName(e.target.value)} /> */}
                    {!success ?
                        <React.Fragment>
                            <label>
                                Adresse email
                            </label>
                            <input type="email" onChange={e => setEmail(e.target.value)} />
                            <label>
                                Mot de passe <small>(min. 8 caractères)</small>
                            </label>
                            <input type="password" onChange={e => setPassword(e.target.value)} />
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <label>
                                Code de validation
                            </label>
                            <input type="number" onChange={e => setToken(e.target.value)} value={token} />
                        </React.Fragment>}
                    {processing ?
                        <img src={LOADER} width="100" alt="Running man" />
                        :
                        !success ?
                        <div className="button" onClick={register}>
                            <FaPlay /> Créer compte
                        </div>
                        :    
                        <div className="button" onClick={confirmAccount}>
                            <FaCheck /> Valider
                        </div>
                    }
                    <p className="name">
                        <small>Vous avez déjà un compte ? <a href="/merchants/signin" className="highlight d-inline-block">S'identifier</a></small>
                    </p>
                </div>
            </div>
            <footer>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <p className="copyright">
                    {t('home.website')}
                    <br />
                    <strong>Copyright @Codinsight. 2024</strong>
                </p>
                <div className="entry">
                    <p>
                        <strong>Editeur du Site</strong>
                    </p>
                    <p>
                        L'éditeur du Site est Codinsight, société par actions simplifiée au capital de 8000€,
                        immatriculée 907 943 088 au RCS de Montpellier.
                    </p>
                    <p>
                        N° TVA : FR18907943088
                        <br />
                        Email : contact@codinsight.com
                        <br />
                        Directeur de publication : M. Fabien BUCAMP.
                    </p>
                </div>
                <div className="entry">
                    <p>
                        <strong>Hébergeur du Site</strong>
                    </p>
                    <p>
                        Ce site est hébergé exclusivement sur des datacenters en France (Paris) de la société Amazon Web Services
                    </p>
                    <p>
                        Amazon Web Services Inc.
                        <br />
                        410 Terry Avenue North,
                        <br />
                        Seattle, WA 98109-5210, USA
                    </p>
                </div>
                <div className="entry">
                    <div className="link" onClick={() => window.open("https://ios.loyalbuds.app")}>
                        <FaAppStore />
                    </div>
                    <div className="link" onClick={() => window.open("https://play.loyalbuds.app")}>
                        <FaGooglePlay />
                    </div>
                    <div className="link" onClick={() => window.open("https://discord.com/invite/sHbvCwhPGz")}>
                        <FaDiscord />
                    </div>
                    <div className="link" onClick={() => window.open("https://twitter.com/LoyalBuds")}>
                        <FaTwitter />
                    </div>
                    <div className="link" onClick={() => window.open("https://www.instagram.com/loyalbudsgame/")}>
                        <FaInstagram />
                    </div>
                </div>
            </footer>
            <ToastContainer />
        </div>
    );
}

export default Register;
