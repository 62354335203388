import 'react-toastify/dist/ReactToastify.css';
import React, { useState } from 'react';

import LOYALBUDS from "../media/images/loyalbuds_white_pro.png";
import LOADER from "../media/images/loader.gif"
import { FaAppStore, FaDiscord, FaGooglePlay, FaInstagram, FaSignInAlt, FaTwitter, FaUserAstronaut, FaShoppingBag, FaSearch, FaTimes, FaMapMarkerAlt, FaCheck } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_OPTIONS } from '../constants';
import BusinessService from '../services/business_services'

//TRANSLATION
import { useTranslation } from 'react-i18next';

function SearchBusiness() {
    const { t } = useTranslation();
    const [businesses, setBusinesses] = useState([]);
    const [name, setName] = useState();
    const [address, setAddresss] = useState();
    const [city, setCity] = useState();
    const [country, setCountry] = useState();
    const [processing, setProcessing] = useState(false);

    const searchBusinesses = async () => {
        if (!name || !address || !city || !country)
            toast.error('Veuillez remplir les champs requis', TOAST_OPTIONS);
        else {
            setProcessing(true)
            try {
                const data = await BusinessService.searchBusiness(name, address, city, country);
                setBusinesses(data.business);
                console.log(data);
            } catch (e) {
                toast.error(e.response && e.response.data ? e.response.data.message : e.message, TOAST_OPTIONS);
            }
            setProcessing(false)
        }
    }

    const selectBusiness = async (b) => {
        await BusinessService.registerBusiness(name, b.place_id);
        toast.success('Commerce sélectionné avec succès', TOAST_OPTIONS);
        setTimeout(() => {
            window.location.href = "/merchants/profile"
        }, 1000);
    }

    return (
        <div className="container">
            <header>
                <a href="/" className="left_entry"><FaUserAstronaut /> Joueurs</a>
                <a href="/merchants" className="left_entry active"><FaShoppingBag /> Commerçants</a>
                <div className="sign_in" onClick={() => window.location.href = "/merchants/signin"}>
                    S'identifier
                </div>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <a href="https://discord.com/invite/sHbvCwhPGz" target="_blank" rel="noreferrer" className="right_entry social"><FaDiscord /></a>
                <a href="https://twitter.com/LoyalBuds" target="_blank" rel="noreferrer" className="right_entry social"><FaTwitter /></a>
                <a href="https://www.instagram.com/loyalbudsgame/" target="_blank" rel="noreferrer" className="right_entry social"><FaInstagram /></a>
                <span className="right_entry search_entry" onClick={() => window.location.href = "/merchants/signin"}><FaSignInAlt /></span>
                <span className="right_entry mobile_entry active" onClick={() => window.location.href = "/merchants"}><FaShoppingBag /></span>
                <span className="right_entry mobile_entry" onClick={() => window.location.href = "/"}><FaUserAstronaut /></span>
            </header>
            <div className="content simple">
                <span className="notice">
                    Rechercher et valider votre compte Business PRO
                </span>
                <h1 className="title">
                    Enregistrer votre commerce sur LoyalBuds
                </h1>
                <p>
                    Veuillez remplir toutes les informations demandées
                </p>
                {businesses.length > 0 ?
                    <div className="form">
                        <p>
                            Veuillez sélectionner votre commerce
                        </p>
                        {businesses.map(b => <div key={b.place_id} className="distrib">
                            <div className="info_box">
                                <label style={{ marginTop: "5px" }}>
                                    <strong>{b.business_name}</strong>
                                    <br />
                                    <small><FaMapMarkerAlt /> {b.address}</small>
                                </label>
                            </div>
                            <div className="remove" onClick={() => selectBusiness(b)}><FaCheck /></div>
                        </div>)}
                        <div className="cancel" onClick={() => setBusinesses([])}>
                            <FaTimes /> {t('merchants.cancel')}
                        </div>
                    </div>
                    :
                    <div className="form">
                        <label>
                            Nom du commerce
                        </label>
                        <input type="text" onChange={(e) => setName(e.target.value)} />
                        <label>
                            Addresse
                        </label>
                        <input type="text" onChange={(e) => setAddresss(e.target.value)} />
                        <label>
                            Ville
                        </label>
                        <input type="text" onChange={(e) => setCity(e.target.value)} />
                        <label>
                            Pays
                        </label>
                        <input type="text" onChange={(e) => setCountry(e.target.value)} />
                        {processing ?
                            <img src={LOADER} width="100" alt="Running man" />
                            :
                            <div className="button" onClick={searchBusinesses}>
                                <FaSearch /> Rechercher
                            </div>}
                        <p className="name">
                            <small>Vous ne trouvez pas votre commerce ? <a href="mailTo:contact@loyalbuds.io" className="highlight d-inline-block">Nous contacter</a></small>
                        </p>
                    </div>}
            </div>
            <footer>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <p className="copyright">
                    {t('home.website')}
                    <br />
                    <strong>Copyright @Codinsight. 2024</strong>
                </p>
                <div className="entry">
                    <p>
                        <strong>Editeur du Site</strong>
                    </p>
                    <p>
                        L'éditeur du Site est Codinsight, société par actions simplifiée au capital de 8000€,
                        immatriculée 907 943 088 au RCS de Montpellier.
                    </p>
                    <p>
                        N° TVA : FR18907943088
                        <br />
                        Email : contact@codinsight.com
                        <br />
                        Directeur de publication : M. Fabien BUCAMP.
                    </p>
                </div>
                <div className="entry">
                    <p>
                        <strong>Hébergeur du Site</strong>
                    </p>
                    <p>
                        Ce site est hébergé exclusivement sur des datacenters en France (Paris) de la société Amazon Web Services
                    </p>
                    <p>
                        Amazon Web Services Inc.
                        <br />
                        410 Terry Avenue North,
                        <br />
                        Seattle, WA 98109-5210, USA
                    </p>
                </div>
                <div className="entry">
                    <div className="link" onClick={() => window.open("https://ios.loyalbuds.app")}>
                        <FaAppStore />
                    </div>
                    <div className="link" onClick={() => window.open("https://play.loyalbuds.app")}>
                        <FaGooglePlay />
                    </div>
                    <div className="link" onClick={() => window.open("https://discord.com/invite/sHbvCwhPGz")}>
                        <FaDiscord />
                    </div>
                    <div className="link" onClick={() => window.open("https://twitter.com/LoyalBuds")}>
                        <FaTwitter />
                    </div>
                    <div className="link" onClick={() => window.open("https://www.instagram.com/loyalbudsgame/")}>
                        <FaInstagram />
                    </div>
                </div>
            </footer>
            <ToastContainer />
        </div>
    );
}

export default SearchBusiness;
