export const routeApi = "https://api.sandbox.loyalbuds.app/api/v1"
export const SITE_KEY = "941b85cb-6ff6-49d2-be4a-38d9f1a7e5ea"
export const TOAST_OPTIONS = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    theme: "colored",
} 