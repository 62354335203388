import api from './api';

class CardService {
  async getCollections(deactivated = false) {
    return await api
      .get(`/card/collection?deactivated=${deactivated}`)
      .then(async (response) => {
        return response.data.collections;
      });
  }

  async deactivateCollection(collection_uuid) {
    return await api
      .post(`/card/collection/deactivate`, { collection_uuid })
      .then(async (response) => {
        return response.data;
      });
  }

  async activateCollection(collection_uuid) {
    return await api
      .post(`/card/collection/reactivate`, { collection_uuid })
      .then(async (response) => {
        return response.data;
      });
  }

  async createCollection(name, description) {
    return await api
      .post(`/card/collection`, { name, description })
      .then(async (response) => {
        return response.data;
      });
  }

  async updateCollection(collection_uuid, name, description) {
    return await api
      .post(`/card/collection/${collection_uuid}`, { name, description })
      .then(async (response) => {
        return response.data;
      });
  }

  async getCollectionBuds(collection, deactivated = false, target) {
    return await api
      .get(`/card/bud?deactivated=${deactivated}&collection=${collection}${target && target !== "all" ? `&target=${target}` : ""}`)
      .then(async (response) => {
        return response.data.available_buds;
      });
  }

  async getCollectionNumber(collection) {
    return await api
      .get(`/card/bud?collection=${collection}`)
      .then(async (response) => {
        return response.data.available_buds.length + 1;
      });
  }

  async getBuds(deactivated = false, target) {
    return await api
      .get(`/card/bud?deactivated=${deactivated}${target && target !== "all" ? `&target=${target}` : ""}`)
      .then(async (response) => {
        return response.data.available_buds;
      });
  }

  async getLevels() {
    return await api
      .get(`/card/level`)
      .then(async (response) => {
        return response.data.levels;
      });
  }

  async createBud(level, img_data, bud_name, description, collection_uuid, target) {
    return await api
      .post(`/card/bud`, { level, img_data, bud_name, description, collection_uuid, target })
      .then(async (response) => {
        return response.data;
      });
  }

  async updateBud(bud_uuid, level, img_data, bud_name, description, collection_uuid) {
    return await api
      .post(`/card/bud/${bud_uuid}`, { level, img_data, bud_name, description, collection_uuid })
      .then(async (response) => {
        return response.data;
      });
  }

  async updateBudSale(bud_sale_uuid, price, max_sales, start_sale, end_sale, sale_status) {
    return await api
      .post(`/card/bud/sale/${bud_sale_uuid}`, { price, max_sales, start_sale, end_sale, sale_status: (sale_status.toString() === 'true') })
      .then(async (response) => {
        return response.data;
      });
  }

  async newBudSale(bud_uuid, price, max_sales, start_sale, end_sale, sale_status) {
    console.log(sale_status)
    return await api
      .post(`/card/bud/sale`, { bud_uuid, price, max_sales, start_sale, end_sale, sale_status })
      .then(async (response) => {
        return response.data;
      });
  }

  async deactivateBud(bud_uuid) {
    return await api
      .post(`/card/bud/deactivate`, { bud_uuid })
      .then(async (response) => {
        return response.data;
      });
  }

  async activateBud(bud_uuid) {
    return await api
      .post(`/card/bud/reactivate`, { bud_uuid })
      .then(async (response) => {
        return response.data;
      });
  }

  async getBusinessCards() {
    return await api
      .get(`/card/bud/sale?target=business`)
      .then(async (response) => {
        return response.data.current_sale;
      });
  }

  async getUnitPrice() {
    return await api
      .get(`/card/bud/sale?target=business`)
      .then(async (response) => {
        return response.data.card_unit_price;
      });
  }

  async purchaseBusinessBud(bud_uuid, nb_card = 100) {
    return await api
      .post(`/card/bud/buy`, { bud_uuid, nb_card })
      .then(async (response) => {
        return response.data.status;
      });
  }

  async searchBuds(search) {
    return await api
      .get(`card/bud/search?bud_name=${search}`)
      .then(async (response) => {
        console.log(response.data)
        return response.data.buds;
      });
  }


}

// eslint-disable-next-line 
export default new CardService();
