import React, { useState } from 'react';

import LOYALBUDS from "../media/images/loyalbuds_white.png";
import BUD_ONE from "../media/images/bud_one.png";
import BUD_TWO from "../media/images/bud_two.png";
import BUD_THREE from "../media/images/bud_three.png";
import BUD_FOUR from "../media/images/bud_four.png";
import BUD_FIVE from "../media/images/bud_five.png";
import BUD_SET_ONE from "../media/images/bud_set_one.png";
import BUD_SET_TWO from "../media/images/bud_set_two.png";
import GPLAY from "../media/images/google_play.png";
import APPSTORE from "../media/images/app_store.png";
import BUDOLYMPICS from "../media/images/bud_olympics.png"
import Tilt from 'react-parallax-tilt';
import { FaAppStore, FaDiscord, FaGooglePlay, FaInstagram, FaSearch, FaShoppingBag, FaTwitter, FaUserAstronaut } from "react-icons/fa";
import { isAndroid, isIOS } from "react-device-detect";

//TRANSLATION
import { useTranslation } from 'react-i18next';

function Home() {
    const { t, i18n } = useTranslation();
    const [search, setSearch] = useState();

    const handleLangChange = lang => {
        i18n.changeLanguage(lang);
    };

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            window.location.href = `/search/buds/${search}`
        }
    };

    return (
        <div className="container">
            <header>
                <a href="/" className="left_entry active"><FaUserAstronaut /> {t('home.player')}</a>
                <a href="/merchants" className="left_entry"><FaShoppingBag /> {t('home.merchants')}</a>
                <div className="search">
                    <div className="search_icon"><FaSearch /></div>
                    <input type="text" placeholder={t('home.search')}
                        onChange={event => setSearch(event.target.value)}
                        onKeyDown={handleKeyDown} />
                </div>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                {i18n.language === "en" || i18n.language === "en-US" ?
                    <a href={`#fr`} onClick={() => handleLangChange('fr')} className="right_entry social" style={{ marginTop: "-2px" }}><small>FR</small></a>
                    :
                    <a href={`#en`} onClick={() => handleLangChange('en')} className="right_entry social" style={{ marginTop: "-2px" }}><small>EN</small></a>}
                <a href="https://discord.com/invite/sHbvCwhPGz" target="_blank" rel="noreferrer" className="right_entry social"><FaDiscord /></a>
                <a href="https://twitter.com/LoyalBuds" target="_blank" rel="noreferrer" className="right_entry social"><FaTwitter /></a>
                <a href="https://www.instagram.com/loyalbudsgame/" target="_blank" rel="noreferrer" className="right_entry social"><FaInstagram /></a>
                <span className="right_entry mobile_entry" onClick={() => window.location.href = "/merchants"}><FaShoppingBag /></span>
                <span className="right_entry mobile_entry active" onClick={() => window.location.href = "/"}><FaUserAstronaut /></span>
                {i18n.language === "en" || i18n.language === "en-US" ?
                    <a href={`#fr`} onClick={() => handleLangChange('fr')} className="right_entry mobile_entry" style={{ marginTop: "-2px" }}><small>FR</small></a>
                    :
                    <a href={`#en`} onClick={() => handleLangChange('en')} className="right_entry mobile_entry" style={{ marginTop: "-2px" }}><small>EN</small></a>}
            </header>
            <div className="content">
                <span className="notice">Collect, play & level up</span>
                <h1 className="title">
                    {t('home.title')}
                </h1>
                <p>
                    {t('home.tagline')}
                    <big className="highlight">
                        {t('home.get_buds')}
                    </big>
                </p>
                <div className="store_holder">
                    {!isAndroid && <img src={APPSTORE} className="store" alt="Apple App Store" onClick={() => window.open('https://apps.apple.com/us/app/loyalbuds/id6476626608?platform=iphone')} />}
                    {!isIOS && <img src={GPLAY} className="store" alt="Google Play Store" onClick={() => window.open('https://play.google.com/store/apps/details?id=com.codinsight.LoyalBuds')} />}
                </div>
                <div className="liner centered">
                    <Tilt>
                        <div className="card common">
                            <h2 className="serie">Cyberbud #2</h2>
                            <h5 className="rarity">COMMON</h5>
                            <div className="power">
                                <big>15</big>
                                <span>PWR</span>
                            </div>
                            <div className="bud" style={{ backgroundImage: `url('${BUD_ONE}')` }}></div>
                        </div>
                    </Tilt>
                </div>
                <div className="liner first_left">
                    <Tilt>
                        <div className="card legendary">
                            <h2 className="serie">Raging rival #4</h2>
                            <h5 className="rarity">LEGENDARY</h5>
                            <div className="power">
                                <big>24</big>
                                <span>PWR</span>
                            </div>
                            <div className="bud" style={{ backgroundImage: `url('${BUD_TWO}')` }}></div>
                        </div>
                    </Tilt>
                </div>
                <div className="liner second_left">
                    <Tilt>
                        <div className="card rare">
                            <h2 className="serie">Explorer #3</h2>
                            <h5 className="rarity">RARE</h5>
                            <div className="power">
                                <big>16</big>
                                <span>PWR</span>
                            </div>
                            <div className="bud" style={{ backgroundImage: `url('${BUD_THREE}')` }}></div>
                        </div>
                    </Tilt>
                </div>
                <div className="liner first_right">
                    <Tilt>
                        <div className="card starter">
                            <h2 className="serie_right">Cyberbud #1</h2>
                            <h5 className="rarity_right">STARTER</h5>
                            <div className="power power_right">
                                <big>10</big>
                                <span>PWR</span>
                            </div>
                            <div className="bud" style={{ backgroundImage: `url('${BUD_FOUR}')` }}></div>
                        </div>
                    </Tilt>
                </div>
                <div className="liner second_right">
                    <Tilt>
                        <div className="card epic">
                            <h2 className="serie_right">Mini Knight #3</h2>
                            <h5 className="rarity_right">EPIC</h5>
                            <div className="power power_right">
                                <big>20</big>
                                <span>PWR</span>
                            </div>
                            <div className="bud" style={{ backgroundImage: `url('${BUD_FIVE}')` }}></div>
                        </div>
                    </Tilt>
                </div>
            </div>
            <div className="immersive">
                <div className="box box_left">
                    <h2>
                        {t('home.play_title')}
                    </h2>
                    <p>
                        {t('home.play_blurb')}
                    </p>
                </div>
                <img src={BUD_SET_ONE} className="bud_set" alt="Set of Buds" />
                <div className="box box_right">
                    <h2>
                        {t('home.quest_title')}
                    </h2>
                    <p>
                        {t('home.quest_blurb')}
                    </p>
                </div>
            </div>
            <div className="content simple">
                <span className="notice">{t('home.join_adventure')}</span>
                <h1 className="title">
                    {t('home.join_discord')}
                </h1>
                <p>
                    {t('home.join_blurb')}
                    <big className="highlight">
                        {t('home.build_together')}
                    </big>
                </p>
                <div className="max" onClick={() => window.open("https://discord.com/invite/sHbvCwhPGz")}>
                    <FaDiscord />
                    <img src={BUDOLYMPICS} style={{width: 120, display: "block", margin: "10px auto 0 auto"}} alt="QR Bud Olympic" onClick={() => window.open(" https://play.loyalbuds.app/?bud_token=XErbBOwNwtQhDVVUhXpj")}/>
                </div>
                <div className="immersive" style={{ marginTop: "120px" }}>
                    <div className="box box_left">
                        <h2>
                            {t('home.benefit_title')}
                        </h2>
                        <p>
                            {t('home.benefit_blurb')}
                        </p>
                    </div>
                    <img src={BUD_SET_TWO} className="bud_set" alt="Set of Buds" />
                    <div className="box box_right">
                        <h2>
                            {t('home.liven_title')}
                        </h2>
                        <p>
                            {t('home.liven_blurb')}
                        </p>
                    </div>
                </div>
                <span className="notice max-margin">{t('home.business_tag')}</span>
                <h1 className="title">
                    {t('home.business_title')}
                </h1>
                <p>
                    {t('home.business_blurb')}
                    <big className="highlight">
                        {t('home.business_space')}
                    </big>
                </p>
                <div className="button d-block" onClick={() => window.location.href = "/merchants"}>
                    <FaShoppingBag /> {t('home.learn_more')}
                </div>
            </div>
            <footer>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <p className="privacy">
                    <a href="/privacy">{t('privacy.policy')}</a> | <a href="/cgu">{t('cgu.general_terms')}</a>
                </p>
                <p className="copyright">
                    {t('home.website')}
                    <br />
                    <strong>Copyright @Codinsight. 2024</strong>
                </p>
                <div className="entry">
                    <p>
                        <strong>{t('home.editor_title')}</strong>
                    </p>
                    <p>
                        {t('home.editor_blurb')}
                    </p>
                    <p>
                        N° TVA : FR18907943088
                        <br />
                        Email : contact@codinsight.com
                        <br />
                        {t('home.director_publishing')}
                    </p>
                </div>
                <div className="entry">
                    <p>
                        <strong>{t('home.hosting')}</strong>
                    </p>
                    <p>
                        {t('home.hosting_details')}
                    </p>
                    <p>
                        Amazon Web Services Inc.
                        <br />
                        410 Terry Avenue North,
                        <br />
                        Seattle, WA 98109-5210, USA
                    </p>
                </div>
                <div className="entry">
                    <div className="link" onClick={() => window.open("https://ios.loyalbuds.app")}>
                        <FaAppStore />
                    </div>
                    <div className="link" onClick={() => window.open("https://play.loyalbuds.app")}>
                        <FaGooglePlay />
                    </div>
                    <div className="link" onClick={() => window.open("https://discord.com/invite/sHbvCwhPGz")}>
                        <FaDiscord />
                    </div>
                    <div className="link" onClick={() => window.open("https://twitter.com/LoyalBuds")}>
                        <FaTwitter />
                    </div>
                    <div className="link" onClick={() => window.open("https://www.instagram.com/loyalbudsgame/")}>
                        <FaInstagram />
                    </div>
                    <br />
                    {i18n.language === "en" || i18n.language === "en-US" ? <div className="link" onClick={() => handleLangChange('fr')} style={{ fontSize: "14px", marginTop: "-10px" }}>
                        <span>Version FR</span>
                    </div>
                        :
                        <div className="link" onClick={() => handleLangChange('en')} style={{ fontSize: "14px", marginTop: "-10px" }}>
                            <span>Version EN</span>
                        </div>}
                </div>
            </footer>
        </div>
    );
}

export default Home;
