import React, { useState } from 'react';

import LOYALBUDS from "../media/images/loyalbuds_white.png";
import { FaAppStore, FaDiscord, FaGooglePlay, FaInstagram, FaSearch, FaShoppingBag, FaTwitter, FaUserAstronaut } from "react-icons/fa";

//TRANSLATION
import { useTranslation } from 'react-i18next';

function CGU() {
    const { t, i18n } = useTranslation();
    const [search, setSearch] = useState();

    const handleLangChange = lang => {
        i18n.changeLanguage(lang);
    };

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            window.location.href = `/search/buds/${search}`
        }
    };

    return (
        <div className="container">
            <header>
                <a href="/" className="left_entry"><FaUserAstronaut /> {t('home.player')}</a>
                <a href="/merchants" className="left_entry"><FaShoppingBag /> {t('home.merchants')}</a>
                <div className="search">
                    <div className="search_icon"><FaSearch /></div>
                    <input type="text" placeholder={t('home.search')}
                        onChange={event => setSearch(event.target.value)}
                        onKeyDown={handleKeyDown} />
                </div>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <a href="https://discord.com/invite/sHbvCwhPGz" target="_blank" rel="noreferrer" className="right_entry social"><FaDiscord /></a>
                <a href="https://twitter.com/LoyalBuds" target="_blank" rel="noreferrer" className="right_entry social"><FaTwitter /></a>
                <a href="https://www.instagram.com/loyalbudsgame/" target="_blank" rel="noreferrer" className="right_entry social"><FaInstagram /></a>
                <span className="right_entry mobile_entry" onClick={() => window.location.href = "/merchants"}><FaShoppingBag /></span>
                <span className="right_entry mobile_entry active" onClick={() => window.location.href = "/"}><FaUserAstronaut /></span>
            </header>
            <div className="content simple">
                <h1 className="title">
                    {t('cgu.general_terms')}
                </h1>
                <p>
                    <big className="highlight">
                        {t('cgu.last_date')}
                        <br/>
                        <small>{t('cgu.start_date')}</small>
                    </big>
                </p>
                <h3 className="subtitle left">{t('cgu.subtitle_01')}</h3>
                <p className="justify mb">
                    {t('cgu.txt_01')}
                </p>
                <h3 className="subtitle left">{t('cgu.subtitle_02')}</h3>
                <p className="justify mb">
                    {t('cgu.txt_02')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_03')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_04')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_05')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_06.1')} <a href="/privacy">{t('cgu.privacy_policy')}</a> {t('cgu.txt_06.2')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_07')}
                </p>
                <h2 className="subtitle left">{t('cgu.title_01')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_08')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_09')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_10.1')} <a href="/privacy">{t('cgu.privacy_policy')}</a> {t('cgu.txt_10.2')}
                </p>
                <h2 className="subtitle left">{t('cgu.title_02')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_11')}
                </p>
                <h2 className="subtitle left">{t('cgu.title_03')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_12')}
                </p>
                <h2 className="subtitle left">{t('cgu.title_04')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_13')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_14')}
                </p>
                <h2 className="subtitle left">{t('cgu.title_05')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_15')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_16')}
                </p>
                <h2 className="subtitle left">{t('cgu.title_06')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_17')}
                </p>
                <h2 className="subtitle left">{t('cgu.title_07')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_18')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_19')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_20')} <a href="mailTo:legal@loyalbuds.io" rel="noreferrer" target="_blank">legal@loyalbuds.io</a>
                </p>
                <h2 className="subtitle left">{t('cgu.title_08')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_21')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_22')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_23')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_24')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_25')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_26')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_27')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_28.1')} <a href="mailTo:support@loyalbuds.io" rel="noreferrer" target="_blank">support@loyalbuds.io</a> {t('cgu.txt_28.2')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_30')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_31')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_32')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_33')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_34')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_35')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_36')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_37')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_38')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_39')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_40')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_41')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_42')}
                </p>
                <h2 className="subtitle left">{t('cgu.title_09')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_43')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_44')}
                </p>
                <h2 className="subtitle left">{t('cgu.title_10')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_45')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_46')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_47')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_48')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_49')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_50')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_51')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_52')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_53')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_54')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_55')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_56')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_57')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_58')}
                </p>
                <h2 className="subtitle left">{t('cgu.title_11')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_59')}
                </p>
                <h2 className="subtitle left">{t('cgu.title_12')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_60')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_61')}
                </p>
                <h2 className="subtitle left">{t('cgu.title_13')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_62')}
                </p>
                <h2 className="subtitle left">{t('cgu.title_14')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_63')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_64')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_65')}
                </p>
                <p className="justify mb">
                    <small>{t('cgu.txt_66')}</small>
                    <br/>
                    <small><small>{t('cgu.txt_67')}</small></small>
                    <br/>
                    <small><small>{t('cgu.txt_68')}</small></small>
                    <br/>
                    <small><small>{t('cgu.txt_69')}</small></small>
                    <br/>
                    <small><small>{t('cgu.txt_70')}</small></small>
                </p>
                <p className="justify mb">
                    <small>{t('cgu.txt_71')}</small>
                </p>
                <p className="justify mb">
                    <small>{t('cgu.txt_72')}</small>
                </p>
                <p className="justify mb">
                    <small>{t('cgu.txt_73')}</small>
                </p>
                <p className="justify mb">
                    <small>{t('cgu.txt_74')}</small>
                </p>
                <p className="justify mb">
                    <small>{t('cgu.txt_75')}</small>
                </p>
                <h2 className="subtitle left">{t('cgu.title_15')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_76')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_77')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_78')}
                </p>
                <h2 className="subtitle left">{t('cgu.title_16')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_79')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_80')}
                </p>
                <h2 className="subtitle left">{t('cgu.title_17')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_81')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_82')}
                </p>
                <h2 className="subtitle left">{t('cgu.title_18')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_83.1')} <a href="mailTo:support@loyalbuds.io" rel="noreferrer" target="_blank">support@loyalbuds.io</a> {t('cgu.txt_83.2')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_85')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_86')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_87')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_89')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_90')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_91')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_92')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_93')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_94')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_95')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_96')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_97')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_98')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_99')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_100')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_101')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_102')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_103')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_104')}
                </p>
                <h2 className="subtitle left">{t('cgu.title_19')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_105')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_106')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_107')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_108')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_109')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_110')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_111')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_112')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_113')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_114')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_115')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_116')}
                </p>
                <h2 className="subtitle left">{t('cgu.title_20')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_117')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_118')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_119')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_120')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_121')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_122')}
                </p>
                <h2 className="subtitle left">{t('cgu.title_21')}</h2>
                <p className="justify mb">
                    {t('cgu.txt_123')}
                </p>
                <p className="justify mb">
                    {t('cgu.txt_124.1')} <a href="mailTo:legal@loyalbuds.io" rel="noreferrer" target="_blank">legal@loyalbuds.io</a> {t('cgu.txt_124.2')} 
                </p>
                <p className="justify mb">
                    {t('cgu.txt_125')}
                </p>
            </div>
            <footer>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <p className="privacy">
                    <a href="/privacy">{t('privacy.policy')}</a> | <a href="/cgu">{t('cgu.general_terms')}</a>
                </p>
                <p className="copyright">
                    {t('home.website')}
                </p>
                <div className="entry">
                    <p>
                        <strong>{t('home.editor_title')}</strong>
                    </p>
                    <p>
                        {t('home.editor_blurb')}
                    </p>
                    <p>
                        N° TVA : FR18907943088
                        <br />
                        Email : contact@codinsight.com
                        <br />
                        {t('home.director_publishing')}
                    </p>
                </div>
                <div className="entry">
                    <p>
                        <strong>{t('home.hosting')}</strong>
                    </p>
                    <p>
                        {t('home.hosting_details')}
                    </p>
                    <p>
                        Amazon Web Services Inc.
                        <br />
                        410 Terry Avenue North,
                        <br />
                        Seattle, WA 98109-5210, USA
                    </p>
                </div>
                <div className="entry">
                    <div className="link" onClick={() => window.open("https://ios.loyalbuds.app")}>
                        <FaAppStore />
                    </div>
                    <div className="link" onClick={() => window.open("https://play.loyalbuds.app")}>
                        <FaGooglePlay />
                    </div>
                    <div className="link" onClick={() => window.open("https://discord.com/invite/sHbvCwhPGz")}>
                        <FaDiscord />
                    </div>
                    <div className="link" onClick={() => window.open("https://twitter.com/LoyalBuds")}>
                        <FaTwitter />
                    </div>
                    <div className="link" onClick={() => window.open("https://www.instagram.com/loyalbudsgame/")}>
                        <FaInstagram />
                    </div>
                    <br />
                    {i18n.language === "en" || i18n.language === "en-US" ? <div className="link" onClick={() => handleLangChange('fr')} style={{ fontSize: "14px", marginTop: "-10px" }}>
                        <span>Version FR</span>
                    </div>
                        :
                        <div className="link" onClick={() => handleLangChange('en')} style={{ fontSize: "14px", marginTop: "-10px" }}>
                            <span>Version EN</span>
                        </div>}
                </div>
            </footer>
        </div>
    );
}

export default CGU;
