import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect, useState } from 'react';
import LOYALBUDS from "../media/images/loyalbuds_white_pro.png";
import { FaAppStore, FaDiscord, FaGooglePlay, FaInstagram, FaTwitter } from "react-icons/fa";
import { ToastContainer } from 'react-toastify';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import BusinessService from '../services/business_services';

function DistributionUsers() {
    const { t } = useTranslation();
    const [cards, setCards] = useState([]);
    const [xps, setXps] = useState([]);
    const [active, setActive] = useState("cards");
    const { name, bud_uuid, distribution_token } = useParams();

    const loadMerchant = async () => {
        const data = await BusinessService.businessDistributed(bud_uuid, distribution_token)
        console.log(data)
        setCards(data.distributed_cards.reverse())
        setXps(data.distributed_xp.reverse())
    }

    useEffect(() => {
        loadMerchant()
        // eslint-disable-next-line
    }, []);

    return (
        <div className="container">
            <header>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
            </header>
            <div className="content simple">
                <span className="notice">Détails de distribtuion</span>
                <h1 className="title">
                    <small>Vos distributions de cartes</small>
                    <br />
                    {name}
                </h1>
                <p>
                    <span className={ active === "cards" ? "switch_distrib_active" : "switch_distrib" } onClick={() => setActive("cards")}>
                        Cartes distribuées
                    </span>
                    <span className={ active === "xp" ? "switch_distrib_active" : "switch_distrib" } onClick={() => setActive("xp")}>
                        XP distribués
                    </span>
                </p>
                {active === "cards" ?
                    <table className="distrib_users">
                        <tr>
                            <th className="left"><strong style={{ color: "#EEE", paddingRight: 20 }}>Nom d'utilisateur</strong></th>
                            <th className="left"><strong style={{ color: "#EEE", paddingRight: 20 }}>LVL</strong></th>
                            <th className="left"><strong style={{ color: "#EEE", paddingRight: 20 }}>Date</strong></th>
                        </tr>
                        <tbody>
                            {cards.map((card, index) =>
                                <tr key={`c${index}`}>
                                    <td style={{ paddingRight: 20 }}>
                                        {card.holder_info.username}
                                    </td>
                                    <td style={{ paddingRight: 20 }}>
                                        <strong>{card.holder_info.trophies}</strong>
                                    </td>
                                    <td style={{ paddingRight: 20 }}>
                                        <small>{new Date(card.created_date).toLocaleDateString()} {new Date(card.created_date).toLocaleTimeString()}</small>
                                    </td>
                                </tr>)}
                        </tbody>
                    </table>
                    :
                    <table className="distrib_users">
                        <tr>
                            <th className="left"><strong style={{ color: "#EEE", paddingRight: 20 }}>Nom d'utilisateur</strong></th>
                            <th className="left"><strong style={{ color: "#EEE", paddingRight: 20 }}>LVL</strong></th>
                            <th className="left"><strong style={{ color: "#EEE", paddingRight: 20 }}>Date</strong></th>
                            <th className="left"><strong style={{ color: "#EEE", paddingRight: 20 }}>Nb XP</strong></th>
                        </tr>
                        <tbody>
                            {xps.map((xp, index) =>
                                <tr key={`xp${index}`}>
                                    <td style={{ paddingRight: 20 }}>
                                        {xp.holder_info.username}
                                    </td>
                                    <td style={{ paddingRight: 20 }}>
                                        <strong>{xp.holder_info.trophies}</strong>
                                    </td>
                                    <td style={{ paddingRight: 20 }}>
                                        <small>{new Date(xp.created_date).toLocaleDateString()} {new Date(xp.created_date).toLocaleTimeString()}</small>
                                    </td>
                                    <td>
                                        <strong>+ {xp.nb_xp}</strong>
                                    </td>
                                </tr>)}
                        </tbody>
                    </table>}
            </div>
            <footer>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <p className="privacy">
                    <a href="/privacy">{t('privacy.policy')}</a> | <a href="/cgu">{t('cgu.general_terms')}</a>
                </p>
                <p className="copyright">
                    {t('home.website')}
                </p>
                <div className="entry">
                    <p>
                        <strong>Editeur du Site</strong>
                    </p>
                    <p>
                        L'éditeur du Site est Codinsight, société par actions simplifiée au capital de 8000€,
                        immatriculée 907 943 088 au RCS de Montpellier.
                    </p>
                    <p>
                        N° TVA : FR18907943088
                        <br />
                        Email : contact@codinsight.com
                        <br />
                        Directeur de publication : M. Fabien BUCAMP.
                    </p>
                </div>
                <div className="entry">
                    <p>
                        <strong>Hébergeur du Site</strong>
                    </p>
                    <p>
                        Ce site est hébergé exclusivement sur des datacenters en France (Paris) de la société Amazon Web Services
                    </p>
                    <p>
                        Amazon Web Services Inc.
                        <br />
                        410 Terry Avenue North,
                        <br />
                        Seattle, WA 98109-5210, USA
                    </p>
                </div>
                <div className="entry">
                    <div className="link" onClick={() => window.open("https://ios.loyalbuds.app")}>
                        <FaAppStore />
                    </div>
                    <div className="link" onClick={() => window.open("https://play.loyalbuds.app")}>
                        <FaGooglePlay />
                    </div>
                    <div className="link" onClick={() => window.open("https://discord.com/invite/sHbvCwhPGz")}>
                        <FaDiscord />
                    </div>
                    <div className="link" onClick={() => window.open("https://twitter.com/LoyalBuds")}>
                        <FaTwitter />
                    </div>
                    <div className="link" onClick={() => window.open("https://www.instagram.com/loyalbudsgame/")}>
                        <FaInstagram />
                    </div>
                </div>
            </footer>
            <ToastContainer />
        </div>
    );
}

export default DistributionUsers;