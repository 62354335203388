import React from 'react';
//import CardService from '../services/card_services';

import LOYALBUDS from "../media/images/loyalbuds_white_pro.png";
import BUD_SET_ONE from "../media/images/bud_set_four.png";
import BUD_SET_TWO from "../media/images/bud_set_three.png";
//import Tilt from 'react-parallax-tilt';
import { FaAppStore, FaDiscord, FaGooglePlay, FaInstagram, FaTwitter, FaUserAstronaut, FaShoppingBag, FaPaperPlane, FaSignInAlt, FaPlay } from "react-icons/fa";
//import { isMobile } from 'react-device-detect';

//TRANSLATION
import { useTranslation } from 'react-i18next';

function Merchants() {
  const { t } = useTranslation();
  /* const [buds, setBuds] = useState([]);
  const [selectedBud, setSelectedBud] = useState();
  const [hideBud, setHidebud] = useState(false);
  const [displayDesc, setDisplayDesc] = useState(false);

  const loadBuds = async () => {
    const buds = await CardService.getBusinessCards()
    setBuds(buds)
  } */

  /* const triggerSelection = (bud_uuid) => {
    setSelectedBud(bud_uuid)
    setHidebud(true)
    setTimeout(() => {
      setDisplayDesc(true)
    }, 500);
  }

  const cancelSelection = () => {
    setSelectedBud(null)
    setHidebud(false)
    setDisplayDesc(false)
  }

  const saveBud = async (bud) => {
    window.localStorage.setItem('choosen_bud', JSON.stringify(bud))
    window.location.href = "/merchants/register"
  } */

  /* useEffect(() => {
    loadBuds();
    // eslint-disable-next-line
  }, []); */

  return (
    <div className="container">
      <header>
        <a href="/" className="left_entry"><FaUserAstronaut /> {t('home.player')}</a>
        <a href="/merchants" className="left_entry active"><FaShoppingBag /> {t('home.merchants')}</a>
        <div className="sign_in" onClick={() => window.location.href = "/merchants/signin"}>
          {t('merchants.signin')}
        </div>
        <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
        <a href="https://discord.com/invite/sHbvCwhPGz" target="_blank" rel="noreferrer" className="right_entry social"><FaDiscord /></a>
        <a href="https://twitter.com/LoyalBuds" target="_blank" rel="noreferrer" className="right_entry social"><FaTwitter /></a>
        <a href="https://www.instagram.com/loyalbudsgame/" target="_blank" rel="noreferrer" className="right_entry social"><FaInstagram /></a>
        <span className="right_entry search_entry" onClick={() => window.location.href = "/merchants/signin"}><FaSignInAlt /></span>
        <span className="right_entry mobile_entry active" onClick={() => window.location.href = "/merchants"}><FaShoppingBag /></span>
        <span className="right_entry mobile_entry" onClick={() => window.location.href = "/"}><FaUserAstronaut /></span>
      </header>
      <div className="content pro">
        <span className="notice">Boost, engage & entertain</span>
        <h1 className="title">
          {t('merchants.title')}
        </h1>
        <p>
          {t('merchants.tag')}
        </p>
        <div className="signup_button d-block" onClick={() => window.location.href = "/merchants/register"}>
          <FaPlay /> {t('merchants.signup_now')}
        </div>
        {/* <div className="card_holder">
          {buds.map(bud =>
            <div className="aligner" key={bud.bud_uuid}
              style={{
                transform: (selectedBud === bud.bud_uuid) && hideBud ? "scale(1.1)" : "scale(1)",
                display: (selectedBud !== bud.bud_uuid && hideBud) ? "none" : "inline-block",
                marginLeft: (!isMobile && selectedBud === bud.bud_uuid && hideBud) ? "-300px" : isMobile ? "0px" : "20px",
                marginBottom: (isMobile && selectedBud === bud.bud_uuid && hideBud) ? "200px" : "0px",
                transition: "all .5s ease-out",
              }}>
              {selectedBud === bud.bud_uuid && <div className="desc" style={{ opacity: displayDesc ? 1 : 0 }}>
                <small>{t('merchants.about_bud')}</small>
                <hr />
                {bud.description && bud.description.fr ? bud.description.fr.length > 100 ? `${bud.description.fr.substring(0, 100)}...` : bud.description.fr : bud.description}
                <br />
                <div className="button" onClick={() => saveBud(bud)}>
                  <FaCheck /> {t('merchants.confirm_bud')}
                </div>
                <div className="cancel" onClick={cancelSelection}>
                  <FaTimes /> {t('merchants.cancel')}
                </div>
              </div>}
              <Tilt>
                <div className={`card ${bud.bud_level}`}>
                  <h2 className="serie">{bud.bud_name}</h2>
                  <h5 className="rarity">{bud.bud_level.toUpperCase()}</h5>
                  <div className="power"
                    onClick={() => triggerSelection(bud.bud_uuid)}>
                    <big>{bud.price ? `${bud.price}€` : t('merchants.free')}</big>
                    <span>
                      {bud.max_sales ?
                        <small>{bud.max_sales - bud.nb_sold} | {bud.max_sales}  disponibles</small>
                        :
                        <small>{bud.nb_sold} {t('merchants.available')}</small>
                      }
                    </span>
                  </div>
                  <div className="bud" style={{ backgroundImage: `url('${bud.img_url}')` }}
                    onClick={() => triggerSelection(bud.bud_uuid)}></div>
                </div>
              </Tilt>
            </div>)}
        </div> */}
        <div className="immersive" style={{ marginTop: "120px" }}>
          <div className="box box_left">
            <h2>
              {t('merchants.new_clients_title')}
            </h2>
            <p>
              {t('merchants.new_clients_blurb')}
            </p>
            <h2>
              {t('merchants.loyal_clients_title')}
            </h2>
            <p>
              {t('merchants.loyal_clients_blurb')}
            </p>
          </div>
          <img src={BUD_SET_ONE} className="bud_set" alt="Set of Buds" />
          <div className="box box_right">
            <h2>
              {t('merchants.connect_title')}
            </h2>
            <p>
              {t('merchants.connect_blurb')}
            </p>
          </div>
        </div>
      </div>
      <div className="content simple">
        <span className="notice">{t('merchants.join_adventure')}</span>
        <h1 className="title">
          {t('merchants.join_discord')}
        </h1>
        <p>
          {t('merchants.join_blurb')}
          <big className="highlight">
            {t('merchants.build_together')}
          </big>
        </p>
        <div className="max" onClick={() => window.open("https://discord.com/invite/sHbvCwhPGz")}>
          <FaDiscord />
        </div>
        <div className="immersive" style={{ marginTop: "120px" }}>
          <div className="box box_left">
            <h2>
              {t('merchants.know_title')}
            </h2>
            <p>
              {t('merchants.know_blurb')}
            </p>
          </div>
          <img src={BUD_SET_TWO} className="bud_set" alt="Set of Buds" />
          <div className="box box_right">
            <h2>
              {t('merchants.protect_title')}
            </h2>
            <p>
              {t('merchants.protect_blurb')}
            </p>
          </div>
        </div>
        <span className="notice max-margin">{t('merchants.learn_more')}</span>
        <h1 className="title">
          {t('merchants.contact_now')}
        </h1>
        <p>
          {t('merchants.contact_blurb')}
          <big className="highlight">
            {t('merchants.contact_highlight')}
          </big>
        </p>
        <div className="button d-block" onClick={() => window.open("mailTo:contact@loyalbuds.io")}>
          <FaPaperPlane /> {t('merchants.contact_us')}
        </div>
      </div>
      <footer>
        <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
        <p className="privacy">
          <a href="/privacy">{t('privacy.policy')}</a> | <a href="/cgu">{t('cgu.general_terms')}</a>
        </p>
        <p className="copyright">
          {t('home.website')}
          <br />
          <strong>Copyright @Codinsight. 2024</strong>
        </p>
        <div className="entry">
          <p>
            <strong>{t('home.editor_title')}</strong>
          </p>
          <p>
            {t('home.editor_blurb')}
          </p>
          <p>
            N° TVA : FR18907943088
            <br />
            Email : contact@codinsight.com
            <br />
            {t('home.director_publishing')}
          </p>
        </div>
        <div className="entry">
          <p>
            <strong>{t('home.hosting')}</strong>
          </p>
          <p>
            {t('home.hosting_details')}
          </p>
          <p>
            Amazon Web Services Inc.
            <br />
            410 Terry Avenue North,
            <br />
            Seattle, WA 98109-5210, USA
          </p>
        </div>
        <div className="entry">
          <div className="link" onClick={() => window.open("https://ios.loyalbuds.app")}>
            <FaAppStore />
          </div>
          <div className="link" onClick={() => window.open("https://play.loyalbuds.app")}>
            <FaGooglePlay />
          </div>
          <div className="link" onClick={() => window.open("https://discord.com/invite/sHbvCwhPGz")}>
            <FaDiscord />
          </div>
          <div className="link" onClick={() => window.open("https://twitter.com/LoyalBuds")}>
            <FaTwitter />
          </div>
          <div className="link" onClick={() => window.open("https://www.instagram.com/loyalbudsgame/")}>
            <FaInstagram />
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Merchants;
