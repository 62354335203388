import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect, useRef } from 'react';
import BusinessService from '../services/business_services';
import LOYALBUDS from "../media/images/loyalbuds_white_pro.png";
import LOYALBUDS_BLACK from "../media/images/loyalbuds.png";
import Tilt from 'react-parallax-tilt';
import { FaAppStore, FaDiscord, FaGooglePlay, FaInstagram, FaTwitter, FaSignOutAlt, FaLayerGroup, FaTimes, FaReceipt, FaQrcode, FaPrint, FaCheck, FaClipboard, FaCheckCircle, FaUsers, FaUser, FaPowerOff, FaPlus, FaMapMarkerAlt, FaEnvelope, FaPaperPlane } from "react-icons/fa";
import { isMobile } from 'react-device-detect';
import LOADER from "../media/images/loader.gif";
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_OPTIONS } from '../constants';
import { useParams } from 'react-router';
import QRCode from 'react-qr-code';
import { toJpeg } from 'html-to-image';
import TokenService from '../services/token_services';
import { useTranslation } from 'react-i18next';

function DistributionBuds() {
    const { t } = useTranslation();
    const [title, setTitle] = useState("Distribution");
    const [merchant, setMerchant] = useState({});
    const [bud, setBud] = useState();
    const [distributions, setDistributions] = useState([]);
    const [expiredDistributions, setExpiredDistributions] = useState([]);
    const [selectedDistrib, setSelectDistrib] = useState(null);
    const [reactivatedDistrib, setReactivatedDistrib] = useState(null);
    const [copied, setCopied] = useState();
    const [processing, setProcessing] = useState(false);
    const [printing, setPrinting] = useState(false);
    const [expired, setExpired] = useState(false);
    const [advanced, setAdvanced] = useState(false);
    const [location, setLocation] = useState(false);
    const [visible, setVisible] = useState(true);
    const [xp, setXp] = useState(true);
    const [distance, setDistance] = useState(200);
    const [customAddress, setCustomAddress] = useState();
    const [endDate, setEndDate] = useState(null);
    const [create, setCreate] = useState(null);
    const [name, setName] = useState(null);
    const [quantity, setQuantity] = useState(0);
    const { id } = useParams();

    const printerQR = useRef();

    const loadMerchant = async () => {
        const data = await BusinessService.business()
        setMerchant({
            name: data.business.business_name,
            website: data.business.website,
            address: data.business.address,
            city: data.business.city,
            country: data.business.country,
            description: data.business.description,
            logo: data.business.logo || data.business.logo_url,
            pro: data.business,
            user: data.user,
        })
    }

    const loadBuds = async () => {
        let data = await BusinessService.budsBusiness()
        for (let item of data) {
            if (id === item.bud_uuid)
                setBud(item)
        }
        data = await BusinessService.budDistributions(id)
        setDistributions(data)
        data = await BusinessService.budDistributions(id, true)
        setExpiredDistributions(data)
    }

    const copyLink = async (token) => {
        navigator.clipboard.writeText(`https://play.loyalbuds.app?bud_token=${token}`)
        setCopied(token)
        setTimeout(() => {
            setCopied(null)
        }, 1000);
    }

    const distribute = async () => {
        if (quantity < 1) {
            toast.warning('Veuillez renseigner une quantité de min. 1 carte', TOAST_OPTIONS)
        } else if (!name || name.length < 3) {
            toast.warning('Veuillez renseigner un nom de plus de 3 caractères pour identifier votre distribution', TOAST_OPTIONS)
        } else if (!distance || distance < 50 || distance > 500) {
            toast.warning('Veuillez renseigner une distance de Gélocalisation entre 50 et 500 mètres', TOAST_OPTIONS)
        } else {
            try {
                setProcessing(true)
                const status = await BusinessService.setBudDistribution(bud.bud_uuid, parseInt(quantity), name,
                    endDate ? new Date(endDate).toISOString() : null, location, parseInt(distance), customAddress, visible, xp)
                setProcessing(false)
                if (status) {
                    toast.success("Distribution référencée.", TOAST_OPTIONS)
                    setCreate(null)
                    loadBuds()
                } else {
                    toast.error("La mise en place de la distribution a échoué", TOAST_OPTIONS)
                    setProcessing(false)
                }
            } catch (e) {
                console.log(e)
                setProcessing(false)
                toast.error(e.response && e.response.data ? e.response.data.message : e.message, TOAST_OPTIONS)
            }
        }
    }

    const stop = async (distribution_uuid) => {
        if (window.confirm("Etes vous sûr de supprimer et arrêter cette distribution?")) {
            setProcessing(true)
            const status = await BusinessService.stopBudDistribution(bud.bud_uuid, distribution_uuid)
            setProcessing(false)
            if (status) {
                toast.success("Distribution supprimée.", TOAST_OPTIONS)
                loadBuds()
            } else
                toast.error("La supression de la distribution a échoué", TOAST_OPTIONS)
        }
    }

    const reactivate = async (distribution_name, expiration_date, distribution_uuid, nb_card, strict_location, distance, distribution_address, on_map, distribute_xp) => {
        if (window.confirm("Etes vous sûr de réactiver cette distribution?")) {
            setProcessing(true)
            const status = await BusinessService.reactivateBudDistribution(distribution_name, expiration_date ? new Date(expiration_date).toISOString() : null, distribution_uuid, parseInt(nb_card), strict_location, parseInt(distance), distribution_address, on_map, distribute_xp)
            setProcessing(false)
            if (status) {
                setCreate(null)
                toast.success("Distribution réactivée.", TOAST_OPTIONS)
                loadBuds()
            } else
                toast.error("La réactivation de la distribution a échoué", TOAST_OPTIONS)
        }
    }

    const htmlToImageConvert = async () => {
        await setPrinting(true)
        toJpeg(printerQR.current, {
            quality: 1,
            style: { background: "white" },
        })
            .then((dataUrl) => {
                const link = document.createElement("a");
                link.download = `${bud.bud_info.bud_name}_loyalbuds_qr.png`;
                link.href = dataUrl;
                link.click();
                setTimeout(() => {
                    setPrinting(false);
                }, 1000);
            })
            .catch((err) => {
                console.log(err);
                setPrinting(false);
            });
    };

    useEffect(() => {
        loadMerchant();
        loadBuds();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="container">
            <header>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <div className="menu">
                    <div className="entry active" onMouseOver={() => setTitle("Mes Buds")} onMouseOut={() => setTitle("Distribution")} onClick={() => window.location.href = "/merchants/buds"}>
                        <FaLayerGroup />
                    </div>
                    <div className="merchant" style={{
                        backgroundImage:
                            merchant.logo ?
                                `url('${merchant.logo}')`
                                :
                                merchant.logo_url ?
                                    `url('${merchant.logo_url}')`
                                    :
                                    `url('https://api.dicebear.com/7.x/identicon/svg?seed=${merchant.name}')`
                    }} onMouseOver={() => setTitle("Mon commerce")} onMouseOut={() => setTitle("Distribution")} onClick={() => window.location.href = "/merchants/profile"}></div>
                    <div className="entry" onMouseOver={() => setTitle("Notifier vos clients")} onMouseOut={() => setTitle("Distribution")} onClick={() => window.location.href = "/merchants/notify"}>
                        <FaPaperPlane />
                    </div>
                    <div className="entry" onMouseOver={() => setTitle("Mes achats")} onMouseOut={() => setTitle("Distribution")} onClick={() => window.location.href = "/merchants/receipts"}>
                        <FaReceipt />
                    </div>
                    <div className="entry" onMouseOver={() => setTitle("Mon compte")} onMouseOut={() => setTitle("Distribution")} onClick={() => window.location.href = "/merchants/account"}>
                        <FaUser />
                    </div>
                    <div className="entry" onMouseOver={() => setTitle("Nous contacter")} onMouseOut={() => setTitle("Distribution")} onClick={() => window.location.href = "/merchants/contact"}>
                        <FaEnvelope />
                    </div>
                    <div className="entry" onMouseOver={() => setTitle("Déconnexion")} onMouseOut={() => setTitle("Distribution")} onClick={TokenService.removeUser}>
                        <FaSignOutAlt />
                    </div>
                </div>
            </header>
            <div className="content simple">
                <span className="notice">{title}</span>
                {bud && <h1 className="title">
                    Gérer la distribution de {bud.bud_info.bud_name}
                </h1>}
                <div className="card_holder" style={{ minHeight: "1080px" }}>
                    {bud &&
                        <div className="aligner" key={bud.bud_uuid}
                            style={{
                                marginLeft: isMobile ? "0px" : "-300px",
                                paddingBottom: isMobile ? "600px" : "0px",
                                transition: "all .5s ease-out",
                            }}>
                            <div className="desc" style={isMobile ? { left: "6%" } : {}}>
                                <small>{!expired ? <strong onClick={() => setExpired(false)} style={{ color: "#00FF00" }}>Distributions actives</strong> : <small onClick={() => setExpired(false)}>Distributions actives</small>} | {expired ? <strong onClick={() => setExpired(true)} style={{ color: "#00FF00" }}>Distributions expirées</strong> : <small onClick={() => setExpired(true)}>Distributions expirées</small>}</small>
                                <hr />
                                {distributions.length === 0 && !expired && <strong>-</strong>}
                                {expiredDistributions.length === 0 && expired && <strong>-</strong>}
                                {!expired && distributions.map((distrib, index) =>
                                    <div className="distrib" key={`distrib_${index}`}>
                                        <div className="qr" onClick={() => setSelectDistrib(distrib)}>
                                            <FaQrcode />
                                        </div>
                                        <div className="info_box">
                                            <small>{distrib.distribution_name ? distrib.distribution_name : '-'}</small>
                                            <br />
                                            <strong>{distrib.strict_location && <small><FaMapMarkerAlt /></small>} {distrib.nb_distributed}</strong> | {distrib.nb_card} <small>distribués</small>
                                            <br />
                                            <small>Fin {'>'} {distrib.expiration_date ? `${new Date(distrib.expiration_date).toLocaleDateString()} ${new Date(distrib.expiration_date).toLocaleTimeString().substring(0, 5)}` : "aucune"}</small>
                                            {distance.distribution_address &&
                                                <>
                                                    <br />
                                                    <small>Adresse {'>'} {distrib.distribution_address}</small>
                                                </>
                                            }
                                        </div>
                                        <div className="remove" onClick={() => stop(distrib.distribution_uuid)}><FaTimes /></div>
                                        <div className="clipboard" onClick={() => copyLink(distrib.distribution_token)}>{copied === distrib.distribution_token ? <FaCheckCircle /> : <FaClipboard />}</div>
                                        <a className="users" href={`/merchants/distribution/details/${distrib.distribution_name ? distrib.distribution_name : "Unamed distribution"}/${bud.bud_uuid}/${distrib.distribution_token}`} target="_blank" rel="noreferrer"><FaUsers /></a>
                                    </div>
                                )}
                                {expired && expiredDistributions.map((distrib, index) =>
                                    <div className="distrib" key={`expired_${index}`}>
                                        <div className="info_box">
                                            <small>{distrib.distribution_name ? distrib.distribution_name : '-'}</small>
                                            <br />
                                            <strong>{distrib.strict_location && <small><FaMapMarkerAlt /></small>} {distrib.nb_distributed}</strong> | {distrib.nb_card} <small>distribués</small>
                                            <br />
                                            <small>Fin {'>'} {distrib.expiration_date ? `${new Date(distrib.expiration_date).toLocaleDateString()} ${new Date(distrib.expiration_date).toLocaleTimeString().substring(0, 5)}` : "aucune"}</small>
                                            {distance.distribution_address &&
                                                <>
                                                    <br />
                                                    <small>Adresse {'>'} {distrib.distribution_address}</small>
                                                </>
                                            }
                                        </div>
                                        <div className="remove" onClick={() => { setReactivatedDistrib({ ...distrib, expiration_date: null }); setCreate("reactivate"); }}><FaPowerOff /></div>
                                        <a className="users" href={`/merchants/distribution/details/${distrib.distribution_name ? distrib.distribution_name : "Unamed distribution"}/${bud.bud_uuid}/${distrib.distribution_token}`} target="_blank" rel="noreferrer"><FaUsers /></a>
                                    </div>
                                )}
                                <hr />
                                {create === "new" &&
                                    <React.Fragment>
                                        <strong>Nouvelle distribution</strong>
                                        <br />
                                        <small>Nom de la distribution</small>
                                        <input type="text" placeholder="Nom de la distribution" onChange={(e) => setName(e.target.value)} />
                                        <small>Quantité</small>
                                        <input type="number" placeholder="Quantité" onChange={(e) => setQuantity(e.target.value)} />
                                        <small>Date de fin</small>
                                        <input type="datetime-local" placeholder="Date d'expiration (optionelle)" onChange={(e) => setEndDate(e.target.value)} />
                                        <div style={{ margin: "20px 0" }}>
                                            <small>
                                                Activer la restriction de géolocalisation
                                                <br />
                                                <small>Seuls les joueurs présents à votre adresse pourront scanner et récupérer votre Bud</small>
                                            </small>
                                            <input type="checkbox" onChange={() => setLocation(!location)} checked={location} style={{ width: "20px", height: "20px", margin: "5px 0" }} />
                                        </div>
                                        <small>{advanced ? <strong onClick={() => setAdvanced(false)} style={{ color: "#00FF00" }}>Paramètres avancés</strong> : <span onClick={() => setAdvanced(true)}>Paramètres avancés</span>}</small>
                                        {advanced && <div style={{ margin: "20px 0" }}>
                                            <small>
                                                Activer la visualisation de votre Bud pour les joueurs qui sont proches
                                                <br />
                                                <small>Votre Bud sera alors visible sur la carte (map) des joueurs qui se trouve à {distance}m de votre commerce</small>
                                            </small>
                                            <input type="checkbox" onChange={() => setVisible(!visible)} checked={visible} style={{ width: "20px", height: "20px", margin: "5px 0 5px 5px" }} />
                                            <br />
                                            <small>
                                                Adresse de géolocalisation
                                                <br />
                                                <small>Par défaut la géolocalisation se base sur votre adresse de profil commerçant. Veuillez renseigner une adresse si vous souhaitez que la géolocalisation  se vérifie sur un autre lieu.</small>
                                            </small>
                                            <input type="text" placeholder="Adresse complète" onChange={(e) => setCustomAddress(e.target.value)} />
                                            <br />
                                            <small>
                                                Précision de la géolocalisation
                                                <br />
                                                <small>Choisir une précision entre 50 et 500 mètres.</small>
                                            </small>
                                            <input type="number" placeholder="Précision en mettre" onChange={(e) => setDistance(e.target.value)} min={50} max={500} value={distance} />
                                            <br />
                                            <small>
                                                Activer la distribution d'XP
                                                <br />
                                                <small>Si le joueur a déjà votre carte alors il recevra +1 XP</small>
                                            </small>
                                            <input type="checkbox" onChange={() => setXp(!xp)} checked={xp} style={{ width: "20px", height: "20px", margin: "5px 0 5px 5px" }} />
                                        </div>}
                                        {processing ?
                                            <img src={LOADER} width="100" alt="Running man" />
                                            :
                                            <div className="button single" onClick={distribute}>
                                                <FaCheck /> Créer distribution
                                            </div>}
                                    </React.Fragment>}
                                {!create &&
                                    <React.Fragment>
                                        <div className="button single" onClick={() => setCreate("new")}>
                                            <FaPlus /> Nouvelle distribution
                                        </div>
                                        <div className="cancel single" onClick={() => window.location.href = "/merchants/buds"}>
                                            <FaTimes /> Fermer
                                        </div>
                                    </React.Fragment>
                                }
                                {create === "reactivate" &&
                                    <React.Fragment>
                                        <strong>Réactiver distribution</strong>
                                        <br />
                                        <small>Nom de la distribution</small>
                                        <input type="text" placeholder="Nom de la distribution" defaultValue={reactivatedDistrib.distribution_name} onChange={(e) => setReactivatedDistrib({ ...reactivatedDistrib, distribution_name: e.target.value })} />
                                        <small>Quantité</small>
                                        <input type="number" placeholder="Quantité" defaultValue={reactivatedDistrib.nb_card} onChange={(e) => setReactivatedDistrib({ ...reactivatedDistrib, nb_card: e.target.value })} />
                                        <small>Date de fin</small>
                                        <input type="datetime-local" placeholder="Date d'expiration (optionelle)" onChange={(e) => setReactivatedDistrib({ ...reactivatedDistrib, expiration_date: e.target.value })} />
                                        <div style={{ margin: "20px 0" }}>
                                            <small>
                                                Activer la restriction de géolocalisation
                                                <br />
                                                <small>Seuls les joueurs présents à votre adresse pourront scanner et récupérer votre Bud</small>
                                            </small>
                                            <input type="checkbox" onChange={() => setReactivatedDistrib({ ...reactivatedDistrib, strict_location: !reactivatedDistrib.strict_location })} checked={reactivatedDistrib.strict_location} style={{ width: "20px", height: "20px", margin: "5px 0" }} />
                                        </div>
                                        <small>{advanced ? <strong onClick={() => setAdvanced(false)} style={{ color: "#00FF00" }}>Paramètres avancés</strong> : <span onClick={() => setAdvanced(true)}>Paramètres avancés</span>}</small>
                                        {advanced && <div style={{ margin: "20px 0" }}>
                                            <small>
                                                Activer la visualisation de votre Bud pour les joueurs qui sont proches
                                                <br />
                                                <small>Votre Bud sera alors visible sur la carte (map) des joueurs qui se trouve à {distance}m de votre commerce</small>
                                            </small>
                                            <input type="checkbox" onChange={() => setReactivatedDistrib({ ...reactivatedDistrib, on_map: !reactivatedDistrib.on_map })} checked={reactivatedDistrib.on_map} style={{ width: "20px", height: "20px", margin: "5px 0 5px 5px" }} />
                                            <br />
                                            <small>
                                                Adresse de géolocalisation
                                                <br />
                                                <small>Par défaut la géolocalisation  se base sur votre adresse de profil commerçant. Veuillez renseigner une adresse si vous souhaitez que la géolocalisation  se vérifie sur un autre lieu.</small>
                                            </small>
                                            <input type="text" placeholder="Adresse complète" defaultValue={reactivatedDistrib.distribution_address} onChange={(e) => setReactivatedDistrib({ ...reactivatedDistrib, distribution_address: e.target.value })} />
                                            <br />
                                            <small>
                                                Précision de la géolocalisation
                                                <br />
                                                <small>Choisir une précision entre 50 et 500 mètres.</small>
                                            </small>
                                            <input type="number" placeholder="Précision en mètres" defaultValue={reactivatedDistrib.distance || distance} onChange={(e) => setReactivatedDistrib({ ...reactivatedDistrib, distance: e.target.value })} min={50} max={500} value={reactivatedDistrib.distance} />
                                            <br />
                                            <small>
                                                Activer la distribution d'XP
                                                <br />
                                                <small>Si le joueur a déjà votre carte alors il recevra +1 XP</small>
                                            </small>
                                            <input type="checkbox" onChange={() => setReactivatedDistrib({ ...reactivatedDistrib, distribute_xp: !reactivatedDistrib.distribute_xp })} checked={reactivatedDistrib.distribute_xp} style={{ width: "20px", height: "20px", margin: "5px 0 5px 5px" }} />
                                        </div>}
                                        {processing ?
                                            <img src={LOADER} width="100" alt="Running man" />
                                            :
                                            <div className="button single" onClick={() => reactivate(reactivatedDistrib.distribution_name, reactivatedDistrib.expiration_date, reactivatedDistrib.distribution_uuid, reactivatedDistrib.nb_card, reactivatedDistrib.strict_location, reactivatedDistrib.distance, reactivatedDistrib.distribution_address, reactivatedDistrib.on_map, reactivatedDistrib.distribute_xp)}>
                                                <FaCheck /> Réactiver distribution
                                            </div>}
                                    </React.Fragment>}
                            </div>
                            <Tilt>
                                <div className={`card ${bud.bud_info.bud_level}`} style={isMobile ? { transform: "scale(1.1)" } : {}}>
                                    <h2 className="serie">{bud.bud_info.bud_name}</h2>
                                    <h5 className="rarity">{bud.bud_info.bud_level.toUpperCase()}</h5>
                                    <div className="power">
                                        <big>{bud.nb_item - bud.nb_distributed}</big>
                                        <span>
                                            <small>disponibles</small>
                                        </span>
                                    </div>
                                    <div className="bud" style={{ backgroundImage: `url('${bud.bud_info.img_url}')` }}></div>
                                </div>
                            </Tilt>
                        </div>}
                </div>
            </div>
            <footer>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <p className="privacy">
                    <a href="/privacy">{t('privacy.policy')}</a> | <a href="/cgu">{t('cgu.general_terms')}</a>
                </p>
                <p className="copyright">
                    {t('home.website')}
                </p>
                <div className="entry">
                    <p>
                        <strong>Editeur du Site</strong>
                    </p>
                    <p>
                        L'éditeur du Site est Codinsight, société par actions simplifiée au capital de 8000€,
                        immatriculée 907 943 088 au RCS de Montpellier.
                    </p>
                    <p>
                        N° TVA : FR18907943088
                        <br />
                        Email : contact@codinsight.com
                        <br />
                        Directeur de publication : M. Fabien BUCAMP.
                    </p>
                </div>
                <div className="entry">
                    <p>
                        <strong>Hébergeur du Site</strong>
                    </p>
                    <p>
                        Ce site est hébergé exclusivement sur des datacenters en France (Paris) de la société Amazon Web Services
                    </p>
                    <p>
                        Amazon Web Services Inc.
                        <br />
                        410 Terry Avenue North,
                        <br />
                        Seattle, WA 98109-5210, USA
                    </p>
                </div>
                <div className="entry">
                    <div className="link" onClick={() => window.open("https://ios.loyalbuds.app")}>
                        <FaAppStore />
                    </div>
                    <div className="link" onClick={() => window.open("https://play.loyalbuds.app")}>
                        <FaGooglePlay />
                    </div>
                    <div className="link" onClick={() => window.open("https://discord.com/invite/sHbvCwhPGz")}>
                        <FaDiscord />
                    </div>
                    <div className="link" onClick={() => window.open("https://twitter.com/LoyalBuds")}>
                        <FaTwitter />
                    </div>
                    <div className="link" onClick={() => window.open("https://www.instagram.com/loyalbudsgame/")}>
                        <FaInstagram />
                    </div>
                </div>
            </footer>
            {printing &&
                <div className="frame_runner">
                    <img src={LOADER} className="runner" alt="Running man" />
                </div>
            }
            {selectedDistrib && bud && <div className="frame_qr">
                <div className="content" style={{
                    backgroundImage: 'none',
                    minHeight: 'auto',
                    padding: !printing ? "100px 0" : "0",
                    width: !printing ? "100%" : "fit-content",
                    margin: !printing ? "0 auto" : "0",
                }} ref={printerQR}>
                    {!printing && <div className="printer no-print" onClick={htmlToImageConvert}>
                        <FaPrint />
                    </div>}
                    {!printing && <div className="closer no-print" onClick={() => setSelectDistrib(null)}>
                        <FaTimes />
                    </div>}
                    <div className="card_qr">
                        <img src={LOYALBUDS_BLACK} className="logo" alt="logo LoyalBuds" />
                        <div className={`card ${bud.bud_info.bud_level}`}>
                            <h2 className="serie">{bud.bud_info.bud_name}</h2>
                            <h5 className="rarity">{bud.bud_info.bud_level.toUpperCase()}</h5>
                            <div className="qr_holder">
                                <QRCode size={120}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    bgColor={'transparent'}
                                    value={`https://play.loyalbuds.app?bud_token=${selectedDistrib.distribution_token}`}
                                    viewBox={`0 0 120 120`} />
                            </div>
                            <div className="bud" style={{ backgroundImage: `url('${bud.bud_info.img_url}')` }}></div>
                        </div>
                        <p>Offert par <strong>{merchant.name}</strong></p>
                    </div>
                </div>
            </div>}
            <ToastContainer />
        </div>
    );
}

export default DistributionBuds;