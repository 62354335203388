import React, { useState, useEffect } from 'react';
import CardService from '../services/card_services';

import LOYALBUDS from "../media/images/loyalbuds_white.png";
import BUD_SET_ONE from "../media/images/bud_set_one.png";
import BUD_SET_TWO from "../media/images/bud_set_two.png";
import Tilt from 'react-parallax-tilt';
import { FaAppStore, FaDiscord, FaGooglePlay, FaInstagram, FaSearch, FaShoppingBag, FaTwitter, FaUserAstronaut, FaTimes } from "react-icons/fa";
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router';

//TRANSLATION
import { useTranslation } from 'react-i18next';

function SearchBuds() {
    const { t } = useTranslation();
    const [buds, setBuds] = useState([]);
    const [selectedBud, setSelectedBud] = useState();
    const [hideBud, setHidebud] = useState(false);
    const [displayDesc, setDisplayDesc] = useState(false);
    const [search, setSearch] = useState();
    const { name } = useParams();

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            window.location.href=`/search/buds/${search}`
        }
    };

    const loadBuds = async () => {
      const buds = await CardService.searchBuds(name)
      setBuds(buds)
    }

    const triggerSelection = (bud_uuid) => {
      setSelectedBud(bud_uuid)
      setHidebud(true)
      setTimeout(() => {
        setDisplayDesc(true)
      }, 500);
    }
  
    const cancelSelection = () => {
      setSelectedBud(null)
      setHidebud(false)
      setDisplayDesc(false)
    }

    useEffect(() => {
      loadBuds();
      // eslint-disable-next-line
    }, []);

    return (
        <div className="container">
            <header>
                <a href="/" className="left_entry active"><FaUserAstronaut /> Joueurs</a>
                <a href="/merchants" className="left_entry"><FaShoppingBag /> Commerçants</a>
                <div className="search">
                    <div className="search_icon"><FaSearch /></div>
                    <input type="text" placeholder="Search Buds"
                        onChange={event => setSearch(event.target.value)}
                        onKeyDown={handleKeyDown} />
                </div>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <a href="https://discord.com/invite/sHbvCwhPGz" target="_blank" rel="noreferrer" className="right_entry social"><FaDiscord /></a>
                <a href="https://twitter.com/LoyalBuds" target="_blank" rel="noreferrer" className="right_entry social"><FaTwitter /></a>
                <a href="https://www.instagram.com/loyalbudsgame/" target="_blank" rel="noreferrer" className="right_entry social"><FaInstagram /></a>
                <span className="right_entry mobile_entry" onClick={() => window.location.href = "/merchants"}><FaShoppingBag /></span>
                <span className="right_entry mobile_entry active" onClick={() => window.location.href = "/"}><FaUserAstronaut /></span>
            </header>
            <div className="content">
                <span className="notice">Rechercher Buds</span>
                <h1 className="title">
                    Résultat de recherche
                </h1>
                <div className="card_holder">
                    {buds && buds.map(bud =>
                        <div className="aligner" key={bud.bud_uuid}
                            style={{
                                transform: (selectedBud === bud.bud_uuid) && hideBud ? "scale(1.1)" : "scale(1)",
                                display: (selectedBud !== bud.bud_uuid && hideBud) ? "none" : "inline-block",
                                marginLeft: (!isMobile && selectedBud === bud.bud_uuid && hideBud) ? "-300px" : isMobile ? "0px" : "20px",
                                marginBottom: (isMobile && selectedBud === bud.bud_uuid && hideBud) ? "200px" : "0px",
                                transition: "all .5s ease-out",
                            }}>
                            {selectedBud === bud.bud_uuid && <div className="desc" style={{ opacity: displayDesc ? 1 : 0 }}>
                                <small>A propos de ce Bud</small>
                                <hr />
                                {bud.description && bud.description.fr ? bud.description.fr.length > 100 ? `${bud.description.fr.substring(0, 100)}...` : bud.description.fr : bud.description}
                                <br />
                                <div className="cancel" onClick={cancelSelection}>
                                    <FaTimes /> Fermer
                                </div>
                            </div>}
                            <Tilt>
                                <div className={`card ${bud.bud_level}`}>
                                    <h2 className="serie">{bud.bud_name}</h2>
                                    <h5 className="rarity">{bud.bud_level.toUpperCase()}</h5>
                                    <div className="bud" style={{ backgroundImage: `url('${bud.img_url}')` }}
                                        onClick={() => triggerSelection(bud.bud_uuid)}></div>
                                </div>
                            </Tilt>
                        </div>)}
                </div>
            </div>
            <div className="immersive">
                <div className="box box_left">
                    <h2>
                        Joue à un jeu de cartes fun et stratégique...
                    </h2>
                    <p>
                        Joue à un jeu de cartes fun et tente de remporter des duels contre d'autres joueurs pour
                        faire monter ton niveau, gagner des étoiles et améliorer tes cartes.
                    </p>
                </div>
                <img src={BUD_SET_ONE} className="bud_set" alt="Set of Buds" />
                <div className="box box_right">
                    <h2>
                        Et pars en quête de nouvelles cartes !
                    </h2>
                    <p>
                        Localise des cartes et rends-toi chez les commerçants pour obtenir des cartes uniques
                        à collectionner et compléter ton deck.
                    </p>
                </div>
            </div>
            <div className="content simple">
                <span className="notice">Participer à l'aventure</span>
                <h1 className="title">
                    Rejoindre notre Discord
                </h1>
                <p>
                    Rejoins une communauté fun et grandissante pour faire partie de l'aventure, participer à l'évolution du jeu, connaître
                    les dernières nouveautés et les prochains Buds et évènements.
                    <big className="highlight">
                        Construisons ensemble
                    </big>
                </p>
                <div className="max" onClick={() => window.open("https://discord.com/invite/sHbvCwhPGz")}>
                    <FaDiscord />
                </div>
                <div className="immersive" style={{ marginTop: "120px" }}>
                    <div className="box box_left">
                        <h2>
                            Profite d'offres et d'exclusivités...
                        </h2>
                        <p>
                            Collectionne les cartes des commerçants ! Rends toi réguliérement chez eux
                            pour améliorer tes cartes et profiter d'avantages exclusifs.
                        </p>
                    </div>
                    <img src={BUD_SET_TWO} className="bud_set" alt="Set of Buds" />
                    <div className="box box_right">
                        <h2>
                            Et anime ton quartier !
                        </h2>
                        <p>
                            Une application fun et unique pour rester connecté avec tes commerçants et redynamiser ton quartier, tout en respectant ta vie privée.
                        </p>
                    </div>
                </div>
                <span className="notice max-margin">Vous êtes commerçant ?</span>
                <h1 className="title">
                    Créez votre carte pour dynamiser votre commerce et votre quartier
                </h1>
                <p>
                    Sélectionnez un Bud pour créer une carte unique pour votre commerce dans le jeu pour attirer les joueurs, fidéliser de nouveaux clients et interagir avec eux.
                    <big className="highlight">
                        Visitez l'espace Marchand pour en apprendre plus.
                    </big>
                </p>
                <div className="button d-block" onClick={() => window.location.href = "/merchants"}>
                    <FaShoppingBag /> En savoir plus
                </div>
            </div>
            <footer>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <p className="copyright">
                    {t('home.website')}
                    <br />
                    <strong>Copyright @Codinsight. 2024</strong>
                </p>
                <div className="entry">
                    <p>
                        <strong>Editeur du Site</strong>
                    </p>
                    <p>
                        L'éditeur du Site est Codinsight, société par actions simplifiée au capital de 8000€,
                        immatriculée 907 943 088 au RCS de Montpellier.
                    </p>
                    <p>
                        N° TVA : FR18907943088
                        <br />
                        Email : contact@codinsight.com
                        <br />
                        Directeur de publication : M. Fabien BUCAMP.
                    </p>
                </div>
                <div className="entry">
                    <p>
                        <strong>Hébergeur du Site</strong>
                    </p>
                    <p>
                        Ce site est hébergé exclusivement sur des datacenters en France (Paris) de la société Amazon Web Services
                    </p>
                    <p>
                        Amazon Web Services Inc.
                        <br />
                        410 Terry Avenue North,
                        <br />
                        Seattle, WA 98109-5210, USA
                    </p>
                </div>
                <div className="entry">
                    <div className="link" onClick={() => window.open("https://ios.loyalbuds.app")}>
                        <FaAppStore />
                    </div>
                    <div className="link" onClick={() => window.open("https://play.loyalbuds.app")}>
                        <FaGooglePlay />
                    </div>
                    <div className="link" onClick={() => window.open("https://discord.com/invite/sHbvCwhPGz")}>
                        <FaDiscord />
                    </div>
                    <div className="link" onClick={() => window.open("https://twitter.com/LoyalBuds")}>
                        <FaTwitter />
                    </div>
                    <div className="link" onClick={() => window.open("https://www.instagram.com/loyalbudsgame/")}>
                        <FaInstagram />
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default SearchBuds;
