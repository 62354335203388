import React, { useState, useEffect } from 'react';

import LOYALBUDS from "../media/images/loyalbuds_white_pro.png";
import { FaAppStore, FaDiscord, FaGooglePlay, FaInstagram, FaTwitter, FaSignOutAlt, FaLayerGroup, FaReceipt, FaUser, FaEnvelope, FaPaperPlane } from "react-icons/fa";
import BusinessService from '../services/business_services';
import TokenService from '../services/token_services';

//TRANSLATION
import { useTranslation } from 'react-i18next';

function Receipts() {
    const { t } = useTranslation();
    const [title, setTitle] = useState("Mes achats");
    const [merchant, setMerchant] = useState({});
    const [buds, setBuds] = useState([]);
    const [receipts, setReceipts] = useState([]);

    const loadMerchant = async () => {
        const data = await BusinessService.business()
        setMerchant({
            name: data.business.business_name,
            website: data.business.website,
            address: data.business.address,
            city: data.business.city,
            country: data.business.country,
            description: data.business.description,
            logo: data.business.logo || data.business.logo_url,
            pro: data.business,
            user: data.user
        })
    }

    const loadReceipts = async () => {
        let data = await BusinessService.budsBusiness()
        let bds = {}
        for (let item of data) {
            bds[item.bud_uuid] = item
        }
        setBuds(bds)
        data = await BusinessService.purchases()
        setReceipts(data)
    }

    useEffect(() => {
        loadMerchant()
        loadReceipts()
        // eslint-disable-next-line
    }, []);

    return (
        <div className="container">
            <header>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <div className="menu">
                    <div className="entry" onMouseOver={() => setTitle("Mes Buds")} onMouseOut={() => setTitle("Mes achats")} onClick={() => window.location.href = "/merchants/buds"}>
                        <FaLayerGroup />
                    </div>
                    <div className="merchant" style={{
                        backgroundImage:
                            merchant.logo ?
                                `url('${merchant.logo}')`
                                :
                                `url('https://api.dicebear.com/7.x/identicon/svg?seed=${merchant.name}')`
                    }} onMouseOver={() => setTitle("Mon commerce")} onMouseOut={() => setTitle("Mon commerce")} onClick={() => window.location.href = "/merchants/profile"}></div>
                    <div className="entry" onMouseOver={() => setTitle("Notifier vos clients")} onMouseOut={() => setTitle("Mes achats")} onClick={() => window.location.href = "/merchants/notify"}>
                        <FaPaperPlane />
                    </div>
                    <div className="entry active" onMouseOver={() => setTitle("Mes achats")} onMouseOut={() => setTitle("Mes achats")} onClick={() => window.location.href = "/merchants/receipts"}>
                        <FaReceipt />
                    </div>
                    <div className="entry" onMouseOver={() => setTitle("Mon compte")} onMouseOut={() => setTitle("Mes achats")} onClick={() => window.location.href = "/merchants/account"}>
                        <FaUser />
                    </div>
                    <div className="entry" onMouseOver={() => setTitle("Nous contacter")} onMouseOut={() => setTitle("Mes achats")} onClick={() => window.location.href = "/merchants/contact"}>
                        <FaEnvelope />
                    </div>
                    <div className="entry" onMouseOver={() => setTitle("Déconnexion")} onMouseOut={() => setTitle("Mes achats")} onClick={TokenService.removeUser}>
                        <FaSignOutAlt />
                    </div>
                </div>
            </header>
            <div className="content simple">
                <span className="notice">{title}</span>
                <h1 className="title">
                    Consulter vos achats
                </h1>
                <p>
                    Parcourez vos achats de Buds et de cartes supplémentaires
                </p>
                {receipts.map(receipt => buds[receipt.bud_uuid] &&
                    <div className="immersive" key={receipt.business_purchase_uuid} style={{ marginTop: "40px", paddingBottom: "0" }}>
                        <div className="box">
                            <h4 className="left mb-0">
                                {receipt.purchase_type === 0 && "Achat carte"} {'>'} {buds[receipt.bud_uuid].bud_info.bud_name}
                            </h4>
                            <img src={buds[receipt.bud_uuid].bud_info.img_url} width="120" alt={buds[receipt.bud_uuid].bud_info.bud_name} />
                            <p className="mt-0">
                                Nombre de cartes : <strong>{receipt.quantity}</strong>
                                <br/>
                                <small>Date: {new Date(receipt.created_date).toLocaleDateString()} {new Date(receipt.created_date).toLocaleTimeString()}</small>
                            </p>
                            <h2 className="bold">
                                {receipt.total_price}<small>EUR</small>
                            </h2>
                        </div>
                    </div>
                )}
            </div>
            <footer>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <p className="copyright">
                    {t('home.website')}
                    <br />
                    <strong>Copyright @Codinsight. 2024</strong>
                </p>
                <div className="entry">
                    <p>
                        <strong>Editeur du Site</strong>
                    </p>
                    <p>
                        L'éditeur du Site est Codinsight, société par actions simplifiée au capital de 8000€,
                        immatriculée 907 943 088 au RCS de Montpellier.
                    </p>
                    <p>
                        N° TVA : FR18907943088
                        <br />
                        Email : contact@codinsight.com
                        <br />
                        Directeur de publication : M. Fabien BUCAMP.
                    </p>
                </div>
                <div className="entry">
                    <p>
                        <strong>Hébergeur du Site</strong>
                    </p>
                    <p>
                        Ce site est hébergé exclusivement sur des datacenters en France (Paris) de la société Amazon Web Services
                    </p>
                    <p>
                        Amazon Web Services Inc.
                        <br />
                        410 Terry Avenue North,
                        <br />
                        Seattle, WA 98109-5210, USA
                    </p>
                </div>
                <div className="entry">
                    <div className="link" onClick={() => window.open("https://ios.loyalbuds.app")}>
                        <FaAppStore />
                    </div>
                    <div className="link" onClick={() => window.open("https://play.loyalbuds.app")}>
                        <FaGooglePlay />
                    </div>
                    <div className="link" onClick={() => window.open("https://discord.com/invite/sHbvCwhPGz")}>
                        <FaDiscord />
                    </div>
                    <div className="link" onClick={() => window.open("https://twitter.com/LoyalBuds")}>
                        <FaTwitter />
                    </div>
                    <div className="link" onClick={() => window.open("https://www.instagram.com/loyalbudsgame/")}>
                        <FaInstagram />
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Receipts;
