import React, { useState, useEffect } from 'react';
import CardService from '../services/card_services';
import BusinessService from '../services/business_services';
import TokenService from '../services/token_services';
import LOYALBUDS from "../media/images/loyalbuds_white_pro.png";
import Tilt from 'react-parallax-tilt';
import { FaAppStore, FaDiscord, FaGooglePlay, FaInstagram, FaTwitter, FaReceipt, FaLayerGroup, FaCheck, FaTimes, FaSignOutAlt, FaUser, FaEnvelope, FaPaperPlane } from "react-icons/fa";
import { isMobile } from 'react-device-detect';

//TRANSLATION
import { useTranslation } from 'react-i18next';

function NewBuds() {
    const { t } = useTranslation();
    const [buds, setBuds] = useState([]);
    const [merchant, setMerchant] = useState({});
    const [selectedBud, setSelectedBud] = useState();
    const [hideBud, setHidebud] = useState(false);
    const [displayDesc, setDisplayDesc] = useState(false);
    const [merchantBuds, setMBuds] = useState({});
    const [title, setTitle] = useState("New Bud");

    const loadMerchant = async () => {
        const data = await BusinessService.business()
        setMerchant({
            name: data.business.business_name,
            website: data.business.website,
            address: data.business.address,
            city: data.business.city,
            country: data.business.country,
            description: data.business.description,
            logo: data.business.logo || data.business.logo_url,
            pro: data.business,
            user: data.user
        })
    }

    const loadMerchantBuds = async () => {
        const data = await BusinessService.budsBusiness()
        let mBuds = {}
        for (let b of data) {
            mBuds[b.bud_uuid] = b
        }
        setMBuds(mBuds)
    }

    const loadBuds = async () => {
        const buds = await CardService.getBusinessCards()
        setBuds(buds)
    }

    const triggerSelection = (bud_uuid) => {
        setSelectedBud(bud_uuid)
        setHidebud(true)
        setTimeout(() => {
            setDisplayDesc(true)
        }, 500);
    }

    const cancelSelection = () => {
        setSelectedBud(null)
        setHidebud(false)
        setDisplayDesc(false)
    }

    const saveBud = async (bud) => {
        await CardService.purchaseBusinessBud(bud.bud_uuid)
        window.location.href = "/merchants/buds"
    }

    useEffect(() => {
        loadMerchantBuds();
        loadMerchant();
        loadBuds();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="container">
            <header>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <div className="menu">
                    <div className="entry active" onMouseOver={() => setTitle("Mes Buds")} onMouseOut={() => setTitle("New Bud")} onClick={() => window.location.href = "/merchants/buds"}>
                        <FaLayerGroup />
                    </div>
                    <div className="merchant" style={{
                        backgroundImage:
                            merchant.logo ?
                                `url('${merchant.logo}')`
                                :
                                merchant.logo_url ?
                                    `url('${merchant.logo_url}')`
                                    :
                                    `url('https://api.dicebear.com/7.x/identicon/svg?seed=${merchant.name}')`
                    }} onMouseOver={() => setTitle("Mon commerce")} onMouseOut={() => setTitle("New Bud")} onClick={() => window.location.href = "/merchants/profile"}></div>
                    <div className="entry" onMouseOver={() => setTitle("Notifier vos clients")} onMouseOut={() => setTitle("New Bud")} onClick={() => window.location.href = "/merchants/notify"}>
                        <FaPaperPlane />
                    </div>
                    <div className="entry" onMouseOver={() => setTitle("Mes achats")} onMouseOut={() => setTitle("New Bud")} onClick={() => window.location.href = "/merchants/receipts"}>
                        <FaReceipt />
                    </div>
                    <div className="entry" onMouseOver={() => setTitle("Mon compte")} onMouseOut={() => setTitle("New Bud")} onClick={() => window.location.href = "/merchants/account"}>
                        <FaUser />
                    </div>
                    <div className="entry" onMouseOver={() => setTitle("Nous contacter")} onMouseOut={() => setTitle("New Bud")} onClick={() => window.location.href = "/merchants/contact"}>
                        <FaEnvelope />
                    </div>
                    <div className="entry" onMouseOver={() => setTitle("Déconnexion")} onMouseOut={() => setTitle("New Bud")} onClick={TokenService.removeUser}>
                        <FaSignOutAlt />
                    </div>
                </div>
            </header>
            <div className="content pro">
                <span className="notice">{title}</span>
                <h1 className="title">
                    Votre nouveau Bud
                </h1>
                <p>
                    Parmis les Buds disponibles sélectionnez le Bud à l'image de votre commerce !
                </p>
                <div className="card_holder">
                    {buds.map(bud => !merchantBuds[bud.bud_uuid] &&
                        <div className="aligner" key={bud.bud_uuid}
                            style={{
                                transform: (selectedBud === bud.bud_uuid) && hideBud ? "scale(1.1)" : "scale(1)",
                                display: (selectedBud !== bud.bud_uuid && hideBud) ? "none" : "inline-block",
                                marginLeft: (!isMobile && selectedBud === bud.bud_uuid && hideBud) ? "-300px" : isMobile ? "0px" : "20px",
                                marginBottom: (isMobile && selectedBud === bud.bud_uuid && hideBud) ? "200px" : "0px",
                                transition: "all .5s ease-out",
                            }}>
                            {selectedBud === bud.bud_uuid && <div className="desc" style={{ opacity: displayDesc ? 1 : 0 }}>
                                <small>A propos de ce Bud</small>
                                <hr />
                                {bud.description && bud.description.fr ? bud.description.fr.length > 100 ? `${bud.description.fr.substring(0, 100)}...` : bud.description.fr : bud.description}
                                <br />
                                <div className="button" onClick={() => saveBud(bud)}>
                                    <FaCheck /> Choisir Bud
                                </div>
                                <div className="cancel" onClick={cancelSelection}>
                                    <FaTimes /> Annuler
                                </div>
                            </div>}
                            <Tilt>
                                <div className={`card ${bud.bud_level}`}>
                                    <h2 className="serie">{bud.bud_name}</h2>
                                    <h5 className="rarity">{bud.bud_level.toUpperCase()}</h5>
                                    <div className="power"
                                        onClick={() => triggerSelection(bud.bud_uuid)}>
                                        <big>{bud.price ? `${bud.price}€` : "Gratuit"}</big>
                                        <span>
                                            {bud.max_sales ?
                                                <small>{bud.max_sales - bud.nb_sold} | {bud.max_sales}  disponibles</small>
                                                :
                                                <small>{bud.nb_sold} disponibles</small>
                                            }
                                        </span>
                                    </div>
                                    <div className="bud" style={{ backgroundImage: `url('${bud.img_url}')` }}
                                        onClick={() => triggerSelection(bud.bud_uuid)}></div>
                                </div>
                            </Tilt>
                        </div>)}
                </div>
            </div>
            <footer>
                <img src={LOYALBUDS} className="logo" alt="logo LoyalBuds" />
                <p className="privacy">
                    <a href="/privacy">{t('privacy.policy')}</a> | <a href="/cgu">{t('cgu.general_terms')}</a>
                </p>
                <p className="copyright">
                    {t('home.website')}
                    <br />
                    <strong>Copyright @Codinsight. 2024</strong>
                </p>
                <div className="entry">
                    <p>
                        <strong>Editeur du Site</strong>
                    </p>
                    <p>
                        L'éditeur du Site est Codinsight, société par actions simplifiée au capital de 8000€,
                        immatriculée 907 943 088 au RCS de Montpellier.
                    </p>
                    <p>
                        N° TVA : FR18907943088
                        <br />
                        Email : contact@codinsight.com
                        <br />
                        Directeur de publication : M. Fabien BUCAMP.
                    </p>
                </div>
                <div className="entry">
                    <p>
                        <strong>Hébergeur du Site</strong>
                    </p>
                    <p>
                        Ce site est hébergé exclusivement sur des datacenters en France (Paris) de la société Amazon Web Services
                    </p>
                    <p>
                        Amazon Web Services Inc.
                        <br />
                        410 Terry Avenue North,
                        <br />
                        Seattle, WA 98109-5210, USA
                    </p>
                </div>
                <div className="entry">
                    <div className="link" onClick={() => window.open("https://ios.loyalbuds.app")}>
                        <FaAppStore />
                    </div>
                    <div className="link" onClick={() => window.open("https://play.loyalbuds.app")}>
                        <FaGooglePlay />
                    </div>
                    <div className="link" onClick={() => window.open("https://discord.com/invite/sHbvCwhPGz")}>
                        <FaDiscord />
                    </div>
                    <div className="link" onClick={() => window.open("https://twitter.com/LoyalBuds")}>
                        <FaTwitter />
                    </div>
                    <div className="link" onClick={() => window.open("https://www.instagram.com/loyalbudsgame/")}>
                        <FaInstagram />
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default NewBuds;
